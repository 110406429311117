import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import NumberFormat from 'react-number-format';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import checkoutActions from '../../../redux/actions/checkout';
import { toast } from 'react-toastify';
//IMAGES
import securePay from '../../../assets/images/securepay-logo.png';
import arrowRightWhite from '../../../assets/images/arrow_white_right.svg';
import closeWhite from '../../../assets/images/close-white.svg';


//CSS
import './Cart.scss';
import CartItem from './CartItem';

function Cart(props) {
	const [totalAmount, setTotalAmount] = useState()
	const cart = props.cart.shop && props.cart.events ? [...props.cart.shop, ...props.cart.events] : [];
	useEffect(() => {
		if(props.cart.events){
			let events = [...props.cart.events];
			events.map(event=>{
				if(event.parent.email!==props.userEmail && props.userEmail!==''){
					props.dispatch(checkoutActions.removeCartItem(props.cart, 'events', event));
				}
			})
		}
	}, [props.userEmail])

	useEffect(()=>{
		let total = 0;
		cart.map((zone, i) => {
			total = total + zone.grand_total
		})
		setTotalAmount(total);

	},[props.cart, props.isOpen])

	const handleToggle = () => {
		if (props.isOpen === false) {
			props.dispatch(checkoutActions.openCheckout({}));
		} else {
			props.dispatch(checkoutActions.closeCheckout({}));
		}
	};
	if (!props.isOpen) {
		return null;
	}
	return ReactDOM.createPortal(
		<div className='Overlay'>
			<div className={'cart_popup blue-bg  active'}>
				<div className='cart_popup_container'>
					<div className='d-flex justify-content-between align-items-center'>
						<div className='bold font-37 white text-upper'>Cart</div>
						<div
							className='pointer'
							onClick={() => {
								handleToggle();
							}}
						>
							<img src={closeWhite} alt='close' />
						</div>
					</div>
					{cart.length>0 ?
						cart.map((zone, i) => {
							let data = (
								<div key={i}>
									<CartItem
										maxQuantity={zone.maxQuantity}
										quantity={zone.quantity}
										grand_total={zone.grand_total}
										product_id={zone.product_id}
										price={zone.grand_total}
										size={zone.size}
										image={zone.image}
										name={zone.name}
										index={i}
										zone={zone}
										isEvent={zone.program_id ? true : false}
									/>
								</div>
							);
							return data;
						})
						

						: <div className='regular font-16 white cart_EmptyMessage my-5'>Cart Is Empty</div>}

					{props?.cart?.length > 0 && <div className='d-flex justify-content-between width-80 align-items-center text-center pt-3 mb-5 pb-3'>
						<div className='m-auto p-3 bg-white' style={{ borderRadius: 50 }}>
							<img src={securePay} alt='PayPal' />
						</div>
					</div>}
				</div>
				{props.cartLength > 0 ?
					<Link onClick={handleToggle} to='/checkout'>
						<div className='pointer checkout_bottom position-absolute w-100 pt-4 pl-4 pr-4 pb-4'>
							<div className='checkout_button '>
								<div className='text-upper ml-5' >
									Checkout - <NumberFormat value={Number(totalAmount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
								</div>
								<img
									className='vertical-align-baseline ml-5'
									src={arrowRightWhite}
									alt=''
								/>
							</div>
						</div>
					</Link>
					: <div className='pointer checkout_bottom Cart_cursor-not-allowed position-absolute w-100 pt-4 pl-4 pr-4 pb-4'>
						<div className='checkout_button' style={{ backgroundColor: "#c2c2c2" }}>
							<div className='text-upper ml-5' >
								Checkout - <NumberFormat value={Number(totalAmount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
							</div>
							<img
								className='vertical-align-baseline ml-5'
								src={arrowRightWhite}
								alt=''
							/>
						</div>
					</div>}
			</div>
		</div>,
		document.getElementById('cart')
	);
}
const mapStateToProps = (state) => ({
	isOpen: state.checkout.isOpen,
	cart: state.checkout.cart,
	cartLength: state.checkout.cartLength,
	userEmail: state.auth.email
});

// export default Header
export default connect(mapStateToProps)(Cart);
