import { PostContactUs } from '../../config/config'

import {toast} from 'react-toastify'

export const CONTACT_US_REQUEST = 'CONTACT_US_REQUEST';
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';
export const CONTACT_US_ERROR = 'CONTACT_US_ERROR';

const actions={
    contactUs:(data, history) => async (dispatch) => {
        try {
            dispatch({
                type: CONTACT_US_REQUEST,
            })
            await PostContactUs(data).then((response) => {
                if (response.data.code === 200) {
                    dispatch({
                        type: CONTACT_US_SUCCESS,
                    })
                    toast.success('Email Sent Successfully', {autoClose: false})
                    // history.push('/home')
                } else {
                    toast.error(response?.error || response?.message,{autoClose: false})
                    dispatch({
                        type: CONTACT_US_ERROR,
                    })
                }
            })
        } catch (error) {
            // console.log('CONTACT US ', error.response)
            dispatch({
                type: CONTACT_US_ERROR,
            })
            error?.response?.data?.message?toast.error(error?.response?.data?.message,{autoClose:false}):toast.error(error,{autoClose: false})
        }
    },
}

export default actions