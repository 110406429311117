import { toast } from 'react-toastify';
import { editDetails, getDetails, subscribeMailChimp, editshippingDetails, getshippingDetails, deleteChildrenById, getSchoolsList, getAccountHistory } from '../../config/config'
import authActions from './auth'
import eventActions from './events'
export const SUBSCRIBE_MAIL_REQUEST = 'SUBSCRIBE_MAIL_REQUEST';
export const SUBSCRIBE_MAIL_SUCCESS = 'SUBSCRIBE_MAIL_SUCCESS';
export const SUBSCRIBE_MAIL_ERROR = 'SUBSCRIBE_MAIL_ERROR';

export const EDIT_DETAILS_REQUEST = 'EDIT_DETAILS_REQUEST';
export const EDIT_DETAILS_SUCCESS = 'EDIT_DETAILS_SUCCESS';
export const EDIT_DETAILS_ERROR = 'EDIT_DETAILS_ERROR';

export const EDIT_SHIPPING_DETAILS_REQUEST = 'EDIT_SHIPPING_DETAILS_REQUEST';
export const EDIT_SHIPPING_DETAILS_SUCCESS = 'EDIT_SHIPPING_DETAILS_SUCCESS';
export const EDIT_SHIPPING_DETAILS_ERROR = 'EDIT_SHIPPING_DETAILS_ERROR';

export const GET_SHIPPING_DETAILS_REQUEST = 'GET_SHIPPING_DETAILS_REQUEST';
export const GET_SHIPPING_DETAILS_SUCCESS = 'GET_SHIPPING_DETAILS_SUCCESS';
export const GET_SHIPPING_DETAILS_ERROR = 'GET_SHIPPING_DETAILS_ERROR';

export const GET_DETAILS_REQUEST = 'GET_DETAILS_REQUEST';
export const GET_DETAILS_SUCCESS = 'GET_DETAILS_SUCCESS';
export const GET_DETAILS_ERROR = 'GET_DETAILS_ERROR';

export const GET_HISTORY_REQUEST = 'GET_HISTORY_REQUEST';
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
export const GET_HISTORY_ERROR = 'GET_HISTORY_ERROR';

export const DELETE_CHILD_REQUEST = 'DELETE_CHILD_REQUEST';
export const DELETE_CHILD_SUCCESS = 'DELETE_CHILD_SUCCESS';
export const DELETE_CHILD_ERROR = 'DELETE_CHILD_ERROR';

export const GET_SCHOOLS_REQUEST = 'GET_SCHOOLS_REQUEST';
export const GET_SCHOOLS_SUCCESS = 'GET_SCHOOLS_SUCCESS';
export const GET_SCHOOLS_ERROR = 'GET_SCHOOLS_ERROR';
export const CLEAR_SCHOOL_LIST = 'CLEAR_SCHOOL_LIST';


const actions = {
    subscribeMailChimp: (data) => async (dispatch) => {
        // console.log('data to send email', data)
        try {
            dispatch({
                type: SUBSCRIBE_MAIL_REQUEST,
            })
            await subscribeMailChimp(data).then((response) => {
                // console.log('incoming response', response)
                if (response?.data?.code === 200) {
                    toast.success('Successfully Subscribed To NewsLetter')
                    dispatch({
                        type: SUBSCRIBE_MAIL_SUCCESS,
                        payload: {
                            user: response.data.data.user,
                        },
                    });
                } else {
                    // toast.error(response?.message)
                    dispatch({
                        type: SUBSCRIBE_MAIL_ERROR,
                        payload: {
                            message: response?.message,
                        },
                    });
                }
            })
        }
        catch (error) {
            // error?.response?.data?.message ? toast.error(error.response.data.message) : toast.error(error)
            dispatch({
                type: SUBSCRIBE_MAIL_ERROR,
                payload: {
                    message: 'Invalid Email',
                },
            });
        }
    },
    editDetails: (data, history, parent, is_booking) => async (dispatch) => {
        try {
            dispatch({
                type: EDIT_DETAILS_REQUEST,
            })
            await editDetails(data, 'Bearer ' + localStorage.access_token).then((response) => {
                if (response.data.code === 200) {
                    dispatch({
                        type: EDIT_DETAILS_SUCCESS,
                        payload: {
                            user: response.data.data.user,
                        },
                    });
                    toast.success('Account Info Updated')
                    
                    if(is_booking){
                        history.goBack()
                        dispatch(eventActions.changeIsEventBooking(false))
                    }
                    
                } else {
                    dispatch({
                        type: EDIT_DETAILS_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                    toast.error(`Error Updating : ${response?.data?.message}`)
                }

            })
        }
        catch (error) {
            dispatch({
                type: EDIT_DETAILS_ERROR,
                payload: {
                    message: 'Could Not Edit Profile',
                },
            });
            return
        }
    },
    editshippingDetails: (data, isEventBooking, history) => async (dispatch) => {
        try {
            dispatch({
                type: EDIT_SHIPPING_DETAILS_REQUEST,
            })
            await editshippingDetails(data, 'Bearer ' + localStorage.access_token).then((response) => {
                if (response.data.code === 200) {
                    dispatch({
                        type: EDIT_SHIPPING_DETAILS_SUCCESS,
                        payload: {
                            user: response.data.data.user,
                        },
                    });
                    if(isEventBooking===true){
                        history.goBack();
                        dispatch(eventActions.changeIsEventBooking(false))
                    }
                    toast.success('Shipping Info Updated')
                } else {
                    dispatch({
                        type: EDIT_SHIPPING_DETAILS_ERROR,
                        payload: {
                            message: response?.message,
                        },
                    });
                    toast.error(`Error Updating : ${response?.data?.message}`)
                }

            })
        }
        catch (error) {
            dispatch({
                type: EDIT_SHIPPING_DETAILS_ERROR,
                payload: {
                    message: 'Invalid credentials provided or account is not verified',
                },
            });
            toast.error(`Error Updating : ${error?.message}`)
        }
    },
    getshippingDetails: () => async (dispatch) => {
        try {
            dispatch({
                type: GET_SHIPPING_DETAILS_REQUEST,
            })
            await getshippingDetails('Bearer ' + localStorage.access_token).then((response) => {
                if (response.data.code === 200) {
                    dispatch({
                        type: GET_SHIPPING_DETAILS_SUCCESS,
                        payload: {
                            data: response?.data?.data,
                        },
                    });

                } else {
                    if (response.data.code === 401 && response.data.message === 'Unauthorized') {
                        dispatch(authActions.logOut())
                    } else if (response.data.code === 401 && response.data.error === 'Unauthorized') {
                        dispatch(authActions.logOut())
                    }
                    dispatch({
                        type: GET_SHIPPING_DETAILS_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                }

            })
        }
        catch (error) {
            dispatch({
                type: GET_SHIPPING_DETAILS_ERROR,
                payload: {
                    message: 'Invalid credentials provided or account is not verified',
                },
            });
        }
    },
    getDetails: () => async (dispatch) => {
        try {
            dispatch({
                type: GET_DETAILS_REQUEST,
            })
            await getDetails(localStorage.uuid, 'Bearer ' + localStorage.access_token).then((response) => {
                if (response.data.code === 200) {
                    // console.log('INFOS', response)
                    dispatch({
                        type: GET_DETAILS_SUCCESS,
                        payload: {
                            data: response.data.data,
                        },
                    });

                } else {
                    if (response.data.code === 401 && response.data.message === 'Unauthorized') {
                        dispatch(authActions.logOut())
                    } else if (response.data.code === 401 && response.data.error === 'Unauthorized') {
                        dispatch(authActions.logOut())
                    }
                    dispatch({
                        type: GET_DETAILS_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                }

            })
        }
        catch (error) {
            dispatch({
                type: GET_DETAILS_ERROR,
                payload: {
                    message: 'Invalid credentials provided or account is not verified',
                },
            });
        }
    },

    getAccHistory: (page) => async (dispatch) => {
        try {
            dispatch({
                type: GET_HISTORY_REQUEST,
            })
            await getAccountHistory(page, 'Bearer ' + localStorage.access_token).then((response) => {
                // console.log('ACCOUNT HISTORY RESPONSE', response)
                if (response.data.code === 200) {
                    dispatch({
                        type: GET_HISTORY_SUCCESS,
                        payload: {
                            data: response.data.data.booking_history,
                        },
                    });

                } else {
                    if (response.data.code === 401 && response.data.message === 'Unauthorized') {
                        dispatch(authActions.logOut())
                    } else if (response.data.code === 401 && response.data.error === 'Unauthorized') {
                        dispatch(authActions.logOut())
                    }
                    toast.error(response.message)
                    dispatch({
                        type: GET_HISTORY_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                }

            })
        }
        catch (error) {
            console.loh('ACCOUNT HISTORY ERROR', error.response)
            toast.error(error.response)
            dispatch({
                type: GET_HISTORY_ERROR,
                payload: {
                    message: 'Invalid credentials provided or account is not verified',
                },
            });
        }
    },

    DeleteChild: (data) => async (dispatch) => {
        try {
            dispatch({
                type: DELETE_CHILD_REQUEST,
            })
            // console.log('1', data)
            await deleteChildrenById(data, 'Bearer ' + localStorage.access_token).then((response) => {
                // console.log('DELETEEEEEEEEEEEE',response)
                if (response.data.code === 200) {
                    dispatch({
                        type: DELETE_CHILD_SUCCESS,
                        payload: {
                            data: response.data.data,
                        },
                    });
                    toast.success('Child Deleted')
                    dispatch(actions.getDetails())

                } else {
                    toast.error('Error Deleting Child')
                    if (response.data.code === 401 && response.data.message === 'Unauthorized') {
                        dispatch(authActions.logOut())
                    } else if (response.data.code === 401 && response.data.error === 'Unauthorized') {
                        dispatch(authActions.logOut())
                    }
                    dispatch({
                        type: DELETE_CHILD_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                }


            })

        }
        catch (error) {
            dispatch({
                type: DELETE_CHILD_ERROR,
                payload: {
                    message: 'Invalid credentials provided or account is not verified',
                },
            });
        }
    },
    clearSchool: () => (dispatch) => {
        dispatch({
            type: CLEAR_SCHOOL_LIST,
        })
    },
    getSchools: (data) => async (dispatch) => {
        try {
            dispatch({
                type: GET_SCHOOLS_REQUEST
            })

            await getSchoolsList(data).then((response) => {
                if (response.data.code === 200) {
                    dispatch({
                        type: GET_SCHOOLS_SUCCESS,
                        payload: response.data.data,
                    })

                } else {
                    dispatch({
                        type: GET_SCHOOLS_ERROR,
                    })
                }
            })


        }
        catch (err) {
            dispatch({
                type: GET_SCHOOLS_ERROR,
            })
        }

    }
}
export default actions