import { LoadNewsWithFilter, LoadLatestNews, LoadNewsById } from '../../config/config'

export const LOAD_NEWS_REQUEST = 'LOAD_NEWS_REQUEST';
export const LOAD_NEWS_SUCCESS = 'LOAD_NEWS_SUCCESS';
export const LOAD_NEWS_ERROR = 'LOAD_NEWS_ERROR';


export const LOAD_LATEST_NEWS_REQUEST = 'LOAD_LATEST_NEWS_REQUEST';
export const LOAD_LATEST_NEWS_SUCCESS = 'LOAD_LATEST_NEWS_SUCCESS';
export const LOAD_LATEST_NEWS_ERROR = 'LOAD_LATEST_NEWS_ERROR';

export const LOAD_NEWS_BY_ID_REQUEST = 'LOAD_NEWS_BY_ID_REQUEST';
export const LOAD_NEWS_BY_ID_SUCCESS = 'LOAD_NEWS_BY_ID_SUCCESS';
export const LOAD_NEWS_BY_ID_ERROR = 'LOAD_NEWS_BY_ID_ERROR';


const actions = {
    loadNews: (data) => async (dispatch) => {
        try {
            dispatch({
                type: LOAD_NEWS_REQUEST,
            })

            await LoadNewsWithFilter(data).then((response) => {
                if (response.data.code === 200) {
                    dispatch({
                        type: LOAD_NEWS_SUCCESS,
                        payload: {
                            data: response.data.data,
                        },
                    });
                } else {
                    dispatch({
                        type: LOAD_NEWS_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                }
            })
        } catch (error) {
            dispatch({
                type: LOAD_NEWS_ERROR,
                payload: {
                    message: 'Failed To Load News',
                },
            });
        }
    },
    loadLatestNews:(data)=> async (dispatch) =>{
        try {
            dispatch({
                type:LOAD_LATEST_NEWS_REQUEST,
            })

            await LoadLatestNews(data).then((response)=>{
                if(response.data.code===200){
                    dispatch({
                        type: LOAD_LATEST_NEWS_SUCCESS,
                        payload: {
                            data: response.data.data,
                        },
                    });
                }else{
                    dispatch({
                        type: LOAD_LATEST_NEWS_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                }
            })
        } catch (error) {
            dispatch({
                type: LOAD_LATEST_NEWS_ERROR,
                payload: {
                    message: 'Failed To Load News',
                },
            });
        }
    },

    loadNewsById:(data)=> async (dispatch)=>{
        try {
            dispatch({
                type:LOAD_NEWS_BY_ID_REQUEST,
            })

            await LoadNewsById(data).then((response)=>{
                if(response.data.code===200){
                    dispatch({
                        type:LOAD_NEWS_BY_ID_SUCCESS,
                        payload:{
                            data:response.data.data,
                        },
                    });
                }else{
                    dispatch({
                        type:LOAD_NEWS_BY_ID_ERROR,
                        payload:{
                            message:response.message,
                        },
                    });
                }
            })
        } catch (error) {
            dispatch({
                type:LOAD_NEWS_BY_ID_ERROR,
                payload:{
                    message:'Failed To Load News'
                }
            })
        }
    },
}

export default actions