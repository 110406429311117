import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import "./FormInput.scss";

function RedButton({ disabled, handleClick, type, value, loading }) {
  // const RedButton = withStyles((theme) => ({
  //   root: {
  //     color: "#fff",
  //     backgroundColor: "#D91C24",
  //     border: "1px solid #D91C24",
  //     borderRadius: 0,
  //     transition: "ease all 400ms",
  //     margin: ".5rem 0",
  //     textTransform: "uppercase",
  //     fontFamily: 'colab-bold',
  //     "&:hover": {
  //       backgroundColor: "#fff",
  //       color: "#D91C24",
  //       transition: "ease all 400ms",
  //     },
  //     "&:disabled": {
  //       backgroundColor: "rgb(194, 194, 194)",
  //       color: "#fff",
  //       border: "1px solid rgb(194, 194, 194)",
  //     },
  //   },
  // }))(Button);

  return (
    <Button
      disabled={disabled || loading}
      onClick={handleClick}
      type={type}
      fullWidth
      size='large'
      className='redButton'
    >
      {loading ? <CircularProgress color="inherit" size={30} /> : value}
    </Button>
  );
}

export default RedButton;
