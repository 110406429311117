import axios from "axios";
const SERVER_ENV = process.env.REACT_APP_ENV;
const baseURL =
  SERVER_ENV === "production"
    ? "https://api.no1draftpick.com"
    : "https://api.staging.no1draftpick.com";

const subscribeMailChimp = async (email, headers) => {
  const url = `${baseURL}/web/news-letter/subscribe`;
  return await axios
    .post(url, email)
    .then((response) => response)
    .catch((error) => {
      return error?.response ? error.response : error;
    });
};

/* AUTH */
async function loginUser(data) {
  const url = `${baseURL}/web-user/login`; //url API
  return await axios
    .post(url, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

async function editDetails(data, access_token) {
  // console.log(data)
  const url = `${baseURL}/web-user/account-details`; //url API
  return await axios
    .post(url, data, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => error.response);
}

async function getDetails(uuid, headers) {
  const url = `${baseURL}/web-user/${uuid}/account-details`; //url API
  return await axios
    .get(url, { headers: { Authorization: headers } })
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
}

async function editshippingDetails(data, access_token) {
  const url = `${baseURL}/web-user/shipping-address`; //url API
  return await axios
    .post(url, data.data, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => error.response);
}

async function getshippingDetails(headers) {
  const url = `${baseURL}/web-user/shipping-address`; //url API
  return await axios
    .get(url, { headers: { Authorization: headers } })
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
}

async function logoutUser(headers) {
  const url = `${baseURL}/web-user/logout`;
  return await axios
    .post(url, null, {
      headers: {
        Authorization: headers,
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((error) => {
      return error?.response;
    });
}
async function verifyAccount(data) {
  const url = `${baseURL}/web-user/${data.uuid}/verify-account?${data.signature}`;
  return await axios.patch(url, data).then((response) => response);
}

async function forgotPass(data, token) {
  const url = `${baseURL}/web-user/forgot-password`;
  return await axios
    .post(url, data, { headers: { Authorization: token } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error?.response;
    });
}

//she is checking if the resource is exist then she want to updated

async function createPass(data, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const url = `${baseURL}/web-user/${data.uuid}/create-password?${data.signature}`;
  // console.log('create pass', data);
  return await axios
    .put(url, data.data, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error?.response;
    });
}
//registerview
async function registerUser(data) {
  const url = `${baseURL}/web-user/register`;
  return await axios.post(url, data).then((response) => response);
}
//register/login
async function checkAccount(data) {
  const url = `${baseURL}/web-user/check-account?email=${data.email}`;
  return await axios.get(url).then((response) => response);
}
async function LoadNewsWithFilter(data) {
  const url = `${baseURL}/web/news?${
    data.month ? "filter[month]=" + data.month : ""
  }${data.year ? "&filter[year]=" + data.year : ""}`;
  return await axios.get(url).then((response) => response);
}

async function LoadLatestNews(data) {
  const url = `${baseURL}/web/news?limit=${data.limit}`;
  return await axios.get(url).then((response) => response);
}

async function LoadNewsById(data) {
  const url = `${baseURL}/web/news/${data.newsId}}`;
  return await axios.get(url).then((response) => response);
}

async function LoadEventCategories(data) {
  const url = `${baseURL}/web/categories?item_per_page=${data.limit}`;
  return await axios.get(url).then((response) => response);
}

async function LoadEventByCategoryWithFilter(data) {
  const url = `${baseURL}/web/categories/${data.categoryId}/events?${
    data.termFilter ? "filter[term]=" + data.termFilter + ( data.locationFilter ? "&filter[location]=" + data.locationFilter : "" ) + "&limit=" + data.limit
      : ( data.locationFilter ? "filter[location]=" + data.locationFilter + "&limit=" + data.limit : "limit=" + data.limit ) 
  }`;
  return await axios.get(url).then((response) => response);
}

async function loadEventsCountPerCategory(data) {
  const url = `${baseURL}/web/categories/${data.categoryId}/eventsCount${
    data.termFilter !== "" ? "?filter[term]=" + data.termFilter + ( data.locationFilter !== "" ? "&filter[location]=" + data.locationFilter : "" ) : ( data.locationFilter !== "" ? "?filter[location]=" + data.locationFilter : "" )
  }`;
  return await axios.get(url).then((response) => response);
}

async function loadEventsCountPerTerms(data) {
  const url = `${baseURL}/web/categories/${data.categoryId}/eventsCountByTerms${
    data.locationFilter !== "" ? "?filter[location]=" + data.locationFilter : "" 
  }`;
  return await axios.get(url).then((response) => response);
}

async function LoadCategoryLocations(data) {
  const url = `${baseURL}/web/categories/${data.categoryId}/events${data.termFilter !== "" ? "?filter[term]=" + data.termFilter :  "" }`;
  return await axios.get(url).then((response) => response);
}

async function LoadEventByID(data) {
  const url = `${baseURL}/web/events/${data.eventId}`;
  return await axios
    .get(url)
    .then((response) => response)
    .catch((err) => err.response);
}

async function LoadUpcomingEvents(data) {
  const url = `${baseURL}/web/events?limit=${data.limit}`; //&filter[month]=${data.monthFilter}
  return await axios.get(url).then((response) => response);
}

async function LoadHomeProducts() {
  const url = `${baseURL}/web/products?limit=10`;
  return await axios.get(url).then((response) => response);
}

async function LoadProductsCollectionWithFilter(data) {
  const url = `${baseURL}/web/shop/products?page=${
    data.pageNumber
  }&item_per_page=${data.itemsPerPage}${
    data.categoryId !== " " ? "&slug=" + data.categoryId : ""
  }${
    data.priceSort !== " " ? "&sort-by[discounted_price]=" + data.priceSort : ""
  }${data.dateSort !== " " ? "&sort-by[created_at]=" + data.dateSort : ""}`;
  return await axios.get(url).then((response) => response);
}

async function LoadProductDetails(data) {
  const url = `${baseURL}/web/shop/products/${data.productId}`;
  return await axios.get(url).then((response) => response);
}

async function PostContactUs(data) {
  const url = `${baseURL}/web/contact-us`;
  return await axios.post(url, data).then((response) => response);
}

async function getCouponOffer(code, user) {
  const url = `${baseURL}/web/coupon-code?code=${code}&user=${user}`;
  return await axios.get(url).then((response) => response);
}

async function bookingEvent(data) {
  const url = `${baseURL}/web/program/booking/checkout`;
  return await axios.post(url, data).then((response) => response);
}

async function bookingEventUser(data, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `${token}`,
  };
  const url = `${baseURL}/web-user/program/booking/checkout`;
  return await axios
    .post(url, data, { headers: headers })
    .then((response) => response);
}

async function LoadProductCategories() {
  const url = `${baseURL}/web/shop/categories`;
  return await axios.get(url).then((response) => response);
}

async function loadCategoryDetails(id) {
  const url = `${baseURL}/web/shop/categories/${id}`;
  return await axios.get(url).then((response) => response);
}

async function orderPay(data) {
  const url = `${baseURL}/web/shop/checkout`;
  return await axios.post(url, data).then((response) => response);
}

async function checkout(data, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `${token}`,
  };
  const url = `${baseURL}/web-user/shop/newCheckout`;
  return await axios
    .post(url, data, { headers: headers })
    .then((response) => response);
}

async function orderPayLoggedIn(data, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `${token}`,
  };
  const url = `${baseURL}/web-user/shop/checkout`;
  return await axios
    .post(url, data, { headers: headers })
    .then((response) => response);
}

async function deleteChildrenById(data, access_token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `${access_token}`,
  };
  const url = `${baseURL}/web-user/account-details/${data.parentId}/childrens/${data.childId}`;
  return await axios
    .delete(url, { headers: headers }, null)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
}

async function getSetingsById() {
  const url = `${baseURL}/settings/check-settings?settings_id=1&type=voucher`;
  return await axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
}

async function getSchoolsList(data) {
  const url = `${baseURL}/web/schools${data ? "?search=" + data : ""}`;
  return await axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
}

async function getAccountHistory(page, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `${token}`,
  };
  const url = `${baseURL}/web-user/booking/history?item_per_page=15&page=${page}`;
  return await axios
    .get(url, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
}

async function validateVoucher(data, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `${token}`,
  };

  const url = `${baseURL}/web-user/validate-voucher`;
  return await axios
    .post(url, data, { headers: headers })
    .then((response) => response)
    .catch((error) => error.response);
}

async function getCarouselItems() {
  const url = `${baseURL}/web/banners/home-slider`;
  return await axios
    .get(url)
    .then((response) => response)
    .catch((error) => error);
}

export {
  baseURL,
  SERVER_ENV,
  subscribeMailChimp,
  loginUser,
  logoutUser,
  forgotPass,
  createPass,
  registerUser,
  checkAccount,
  verifyAccount,
  // resetPass,
  // profileImageBaseUrl,
  LoadNewsWithFilter,
  LoadLatestNews,
  LoadNewsById,
  LoadEventCategories,
  LoadEventByCategoryWithFilter,
  LoadCategoryLocations,
  LoadEventByID,
  LoadUpcomingEvents,
  LoadHomeProducts,
  LoadProductDetails,
  LoadProductsCollectionWithFilter,
  PostContactUs,
  getCouponOffer,
  LoadProductCategories,
  orderPay,
  bookingEvent,
  bookingEventUser,
  editDetails,
  editshippingDetails,
  getshippingDetails,
  getDetails,
  orderPayLoggedIn,
  deleteChildrenById,
  getSetingsById,
  getSchoolsList,
  getAccountHistory,
  validateVoucher,
  getCarouselItems,
  checkout,
  loadEventsCountPerCategory,
  loadEventsCountPerTerms,
  loadCategoryDetails
};
