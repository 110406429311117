
import {
    SUBSCRIBE_MAIL_REQUEST,SUBSCRIBE_MAIL_SUCCESS,SUBSCRIBE_MAIL_ERROR,EDIT_DETAILS_REQUEST,EDIT_DETAILS_SUCCESS,EDIT_DETAILS_ERROR,GET_DETAILS_REQUEST,GET_DETAILS_SUCCESS,GET_DETAILS_ERROR,
    EDIT_SHIPPING_DETAILS_REQUEST, EDIT_SHIPPING_DETAILS_SUCCESS, EDIT_SHIPPING_DETAILS_ERROR, GET_SHIPPING_DETAILS_REQUEST, GET_SHIPPING_DETAILS_SUCCESS, GET_SHIPPING_DETAILS_ERROR ,
    GET_SCHOOLS_REQUEST,GET_SCHOOLS_SUCCESS,GET_SCHOOLS_ERROR, GET_HISTORY_SUCCESS, GET_HISTORY_ERROR, GET_HISTORY_REQUEST, CLEAR_SCHOOL_LIST
} from '../actions/account';



 

const initialState = {
    subscribeMailLoading: false,
    subscribeMail:[],

    editDetailsLoading: false,
    editDetailsData: [],

    editshippingDetailsLoading: false,
    editshippingDetailsData: [],

    getshippingDetailsLoading: false,
    getshippingDetails: [],

    getDetailsLoading: false,
    getDetails: [] , 

    schools:[],
    schoolsLoading:false,

    getHistoryLoading:false,
    getHistory:[],

};
function account(state = initialState, action) {
    switch (action.type) {
        case EDIT_DETAILS_REQUEST:
            return Object.assign({}, state, {
                editDetailsLoading: true,

            });
        case EDIT_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                editDetailsLoading: false,
                editDetailsData: action.payload.user,

            });
        case EDIT_DETAILS_ERROR:
            return Object.assign({}, state, {
                editDetailsLoading: false,
                editDetailsData: action.payload.message,
            });
        case EDIT_SHIPPING_DETAILS_REQUEST:
            return Object.assign({}, state, {
                editshippingDetailsLoading: true,

            });
        case EDIT_SHIPPING_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                editshippingDetailsLoading: false,
                editshippingDetailsData: action.payload.user,

            });
        case EDIT_SHIPPING_DETAILS_ERROR:
            return Object.assign({}, state, {
                editshippingDetailsLoading: false,
                editshippingDetailsData: action.payload.message,
            });
        case GET_SHIPPING_DETAILS_REQUEST:
            return Object.assign({}, state, {
                getshippingDetailsLoading: true,
                getshippingDetails:[]
            });
        case GET_SHIPPING_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                getshippingDetailsLoading: false,
                getshippingDetails: action.payload.data
            });
        case GET_SHIPPING_DETAILS_ERROR:
            return Object.assign({}, state, {
                getshippingDetailsLoading: false,
                getshippingDetails:[]
            });
        case GET_DETAILS_REQUEST:
            return Object.assign({}, state, {
                getDetailsLoading: true,
                getDetails: '',

            });
        case GET_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                getDetailsLoading: false,
                getDetails: action.payload.data
            });
        case GET_DETAILS_ERROR:
            return Object.assign({}, state, {
                getDetailsLoading: false,
                getDetails: ''
            });
        case GET_HISTORY_REQUEST:
            return Object.assign({}, state, {
                getHistoryLoading: true,
                getHistory:[]
            });
        case GET_HISTORY_SUCCESS:
            return Object.assign({}, state, {
                getHistoryLoading: false,
                getHistory:action.payload.data
            });
        case GET_HISTORY_ERROR:
            return Object.assign({}, state, {
                getHistoryLoading: false,
                getHistory:[]
            });
        case SUBSCRIBE_MAIL_REQUEST:
            return Object.assign({}, state, {
                subscribeMailLoading: true,
                
            });
        case SUBSCRIBE_MAIL_SUCCESS:
            return Object.assign({}, state, {
                subscribeMailLoading: false,
                subscribeMail: action.payload.data
            });
        case SUBSCRIBE_MAIL_ERROR:
            return Object.assign({}, state, {
                subscribeMailLoading: false,
                subscribeMail: action.payload.message
            });

        case GET_SCHOOLS_REQUEST: 
            return Object.assign({}, state, {
                schools:[],
                schoolsLoading:true,
            });

        case GET_SCHOOLS_SUCCESS: 
            return Object.assign({}, state, {
                schools:action.payload,
                schoolsLoading:false,
            });

        case GET_SCHOOLS_ERROR: 
            return Object.assign({}, state, {
                schoolsLoading:false,
            });
        case CLEAR_SCHOOL_LIST:
            return Object.assign({},state,{
                schools:[]
            })
            
            default:
                return state;
        
        
    }

}

export default account;
