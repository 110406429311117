import {
  CHECKOUT_OPEN,
  CHECKOUT_CLOSE,
  CHECKOUT_STEP_1,
  CHECKOUT_STEP_2,
  CHECKOUT_STEP_3,
  ADD_ITEM_TO_CART,
  REMOVE_ITEM_FROM_CART,
  UPDATE_ITEM_QUANTITY,
  EMPTY_CART_ITEMS,
  ORDER_REQUEST,
  ORDER_SUCCESS,
  ORDER_ERROR,
  EMPTY_ORDER_RESPONSE,
  HANDLE_POPUP,
  REMOVE_EVENTS,
  CHANGE_PAYMENT_TOTAL,
} from "../actions/checkout";

const initialState = {
  isOpen: false,
  currentStep: 1,
  cart: {
    events: [],
    shop: [],
  },
  cartLength: 0,
  buyerInformation: [],
  checkoutOrder: "",
  checkoutOrderLoading: false,
  checkoutDetails: [],
  open: false,
  type: "",
  selectedEvent: {},
  total_amount_to_be_paid: 0,
};

function checkout(state = initialState, action) {

  switch (action.type) {
    case CHECKOUT_OPEN:
      return Object.assign({}, state, {
        isOpen: true,
      });
    case CHECKOUT_CLOSE:
      return Object.assign({}, state, {
        isOpen: false,
      });
    case CHECKOUT_STEP_1:
      return Object.assign({}, state, {
        currentStep: 1,
      });
    case CHECKOUT_STEP_2:
      return Object.assign({}, state, {
        currentStep: 2,
        buyerInformation: action.payload.data,
      });
    case CHECKOUT_STEP_3:
      return Object.assign({}, state, {
        currentStep: 3,
      });
    case ADD_ITEM_TO_CART:
      return Object.assign({}, state, {
        cart: action.payload.data,
        cartLength: action.payload.cartLength,
      });
    case REMOVE_ITEM_FROM_CART:
      return Object.assign({}, state, {
        cart: action.payload.data,
        cartLength: action.payload.cartLength,
      });
    case UPDATE_ITEM_QUANTITY:
      return Object.assign({}, state, {
        cart: action.payload.data,
        cartLength: action.payload.cartLength,
      });
    case REMOVE_EVENTS:
      return Object.assign({}, state, {
        cart: {
          ...state?.cart,
          events: [],
        },
        cartLength: state?.cart?.shop?.length,
      });
    case HANDLE_POPUP:
      return Object.assign({}, state, {
        open: action.payload.open,
        type: action.payload.type,
        selectedEvent: action.payload.selectedEvent,
      });
    case EMPTY_CART_ITEMS:
      return Object.assign({}, state, {
        cart: {
          events: [],
          shop: [],
        },
        cartLength: 0,
      });

    case ORDER_REQUEST:
      return Object.assign({}, state, {
        checkoutOrderLoading: true,
        checkoutOrder: "",
        checkoutDetails: [],
      });
    case ORDER_SUCCESS:
      return Object.assign({}, state, {
        checkoutOrderLoading: false,
        checkoutOrder: action.payload.message,
        checkoutDetails: action.payload.order,
      });
    case ORDER_ERROR:
      return Object.assign({}, state, {
        checkoutOrderLoading: false,
        checkoutOrder: action.payload.message,
        checkoutDetails: [],
      });
    case CHANGE_PAYMENT_TOTAL:
      return Object.assign({}, state, {
        checkoutOrderLoading: false,
        total_amount_to_be_paid: action?.data,
      });
    case EMPTY_ORDER_RESPONSE:
      return Object.assign({}, state, {
        checkoutOrder: "",
      });

    default:
      return state;
  }
}

export default checkout;
