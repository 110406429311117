import moment from "moment";
import { orderPay, orderPayLoggedIn, checkout } from "../../config/config";
import eventsActions from "./events";

export const CHECKOUT_OPEN = "CHECKOUT_OPEN";
export const CHECKOUT_CLOSE = "CHECKOUT_CLOSE";

export const CHECKOUT_STEP_1 = "CHECKOUT_STEP_1";
export const CHECKOUT_STEP_2 = "CHECKOUT_STEP_2";
export const CHECKOUT_STEP_3 = "CHECKOUT_STEP_3";

export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";
export const REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART";
export const UPDATE_ITEM_QUANTITY = "UPDATE_ITEM_QUANTITY";
export const EMPTY_CART_ITEMS = "EMPTY_CART_ITEMS";

export const ORDER_REQUEST = "ORDER_REQUEST";
export const ORDER_SUCCESS = "ORDER_SUCCESS";
export const ORDER_ERROR = "ORDER_ERROR";
export const EMPTY_ORDER_RESPONSE = "EMPTY_ORDER_RESPONSE";

export const CHANGE_PAYMENT_TOTAL = "CHANGE_PAYMENT_TOTAL";

export const HANDLE_POPUP = "HANDLE_POPUP";

export const REMOVE_EVENTS = "REMOVE_EVENTS";

const actions = {
  openCheckout: () => async (dispatch) => {
    dispatch({
      type: CHECKOUT_OPEN,
    });
  },
  closeCheckout: () => async (dispatch) => {
    dispatch({
      type: CHECKOUT_CLOSE,
    });
  },

  step1Checkout: () => async (dispatch) => {
    dispatch({
      type: CHECKOUT_STEP_1,
    });
  },

  step2Checkout: (data) => async (dispatch) => {
    dispatch({
      type: CHECKOUT_STEP_2,
      payload: {
        data: data,
      },
    });
  },
  step3Checkout: () => async (dispatch) => {
    dispatch({
      type: CHECKOUT_STEP_3,
    });
  },

  addCartItem: (cart, data, type) => (dispatch) => {
    let finalCart = {};
    let shop = cart.shop ? [...cart.shop] : [];
    let events = cart.events ? [...cart.events] : [];
    let index = -1;
    if (type === "shop") {
      if (shop.length > 0) {
        shop.map((zone, i) => {
          if (zone.product_id === data.product_id && zone.size === data.size) {
            shop[i] = data;
            index = i;
          }
          return null;
        });
      }
      if (index === -1) {
        shop.push(data);
      }
    } else {
      if (events.length > 0) {
        events.map((zone, i) => {
          if (zone.program_id === data.program_id) {
            events[i] = data;
            index = i;
          }
          return null;
        });
      }
      if (index === -1) {
        events.push(data);
      }
    }

    finalCart = {
      events: events,
      shop: shop,
    };

    dispatch({
      type: ADD_ITEM_TO_CART,
      payload: {
        data: finalCart,
        cartLength: shop.length + events.length,
      },
    });
  },

  removeCartItem: (cart, type, item) => (dispatch) => {
    let finalCart = {};
    let shop = cart.shop ? [...cart.shop] : [];
    let events = cart.events ? [...cart.events] : [];
    if (type === "shop") {
      if (shop.includes(item)) {
        shop.splice(shop.indexOf(item), 1);
      }
    } else {
      if (events.includes(item)) {
        events.splice(events.indexOf(item), 1);
      }
    }

    finalCart = {
      events: events,
      shop: shop,
    };
    dispatch({
      type: REMOVE_ITEM_FROM_CART,
      payload: {
        data: finalCart,
        cartLength: events.length + shop.length,
      },
    });
  },

  updateQuantity: (cart, index, quantity) => (dispatch) => {
    let finalCart = {};
    let data = cart.shop;
    let events = cart.events ? [...cart.events] : [];
    let shop = [];
    data?.map((zone, i) => {
      if (index === i) {
        data[i].quantity = quantity;
        data[i].grand_total = quantity * data[i].price;
      }
      return null;
    });

    data?.map((zone) => {
      shop.push(zone);
      return null;
    });

    finalCart = {
      events: events,
      shop: shop,
    };
    dispatch({
      type: UPDATE_ITEM_QUANTITY,
      payload: {
        data: finalCart,
        cartLength: shop.length + events.length,
      },
    });
  },

  updateBooking: (cart, updated_event) => (dispatch) => {
    let finalCart = {};
    let data = cart.events;
    let shop = cart.shop ? [...cart.shop] : [];
    data?.map((zone, i) => {
      if (zone.program_id === updated_event.program_id) {
        data.splice(i, 1, updated_event);
      }
    });

    finalCart = {
      events: data,
      shop: shop,
    };
    dispatch({
      type: UPDATE_ITEM_QUANTITY,
      payload: {
        data: finalCart,
        cartLength: shop.length + data.length,
      },
    });
    dispatch(actions.handlePopup({ open: false, type: "", selectedEvent: "" }));
  },

  removeEventsFromCart: () => (dispatch) => {
    dispatch({
      type: REMOVE_EVENTS,
    });
  },

  handlePopup: (data) => (dispatch) => {
    dispatch({
      type: HANDLE_POPUP,
      payload: {
        type: data.type,
        open: data.open,
        selectedEvent: data.selectedEvent,
      },
    });
  },

  emptyCart: () => (dispatch) => {
    dispatch({
      type: EMPTY_CART_ITEMS,
    });
  },

  orderAndPay: (data) => async (dispatch) => {
    try {
      dispatch({
        type: ORDER_REQUEST,
      });

      await orderPay(data).then((response) => {
        // console.log('payment success', response)
        dispatch({
          type: ORDER_SUCCESS,
          payload: {
            message: "Transaction Completed Successfully",
            order: response.data.data,
          },
        });
        dispatch(actions.step3Checkout());
        dispatch(actions.emptyCart());
      });
    } catch (error) {
      // console.log('payment error', error.response)
      dispatch({
        type: ORDER_ERROR,
        payload: {
          message: error.response?.data?.message || "Payment Error",
        },
      });
    }
  },

  checkout: (data) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_REQUEST,
      });

      await checkout(data, `Bearer ${localStorage.access_token}`).then(
        (response) => {
          dispatch({
            type: ORDER_SUCCESS,
            payload: {
              message: "Transaction Completed Successfully",
              order: response?.data?.data,
            },
          });

          if(process.env.REACT_APP_ENV==="production"){

            var couponCode = '';
            var couponDiscount = 0;
            if (data.nswVoucher != null && data.nswVoucher.length > 0)
            {
              couponCode = data.nswVoucher[0].voucher_code;
              couponDiscount = data.nswVoucher[0].voucher_value;
            }

            let items = [];
            
            if (data?.shop?.length > 0) {
              data?.shop?.map((product, ind) => {
                items.push({
                  index: ind,
                  item_id: product?.product_id,
                  item_name: product?.name,
                  affiliation: "Online Store",
                  item_list_name: 'Online Shop Products',
                  item_brand: 'N1DP',
                  item_category: 'Shop',
                  item_variant: product?.size,
                  price: product?.grand_total,
                  quantity: product?.quantity
                });
              });
            }

            if (data?.events?.length > 0) {
              data?.events?.map((event, ind) => {
                items.push({
                  index: ind,
                  item_id: event?.event_id,
                  item_name: event?.name,
                  affiliation: "Online Registration",
                  item_list_name: 'Online Events',
                  item_brand: 'N1DP',
                  item_category: 'Events',
                  item_variant: 'Registration',
                  price: event?.price,
                  quantity: event?.children?.length
                });
              });
            }

            //New Tag Manager Custom Event
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
              event: "OrderPlaced",
              ecommerce: {
                value: getState().checkout?.total_amount_to_be_paid,
                currency: "AUD",
                coupon: couponCode,
                discount: couponDiscount,
                items: items
              }
            });
           //New Tag Manager Custom Event
            
          }

          dispatch(actions.step3Checkout());
          dispatch(eventsActions.eventProgramSteps(4));
          dispatch(actions.emptyCart());
          dispatch(eventsActions.resetCouponCode());
          dispatch(eventsActions.changeActiveVoucherKidTotal(0));
          dispatch(eventsActions.changeActiveVoucherKids([]));
        }
      );
    } catch (error) {
      console.log('payment error', error.response)
      dispatch({
        type: ORDER_ERROR,
        payload: {
          message: error.response?.data?.message || "Payment Error",
        },
      });
    }
  },

  orderAndPayLoggedIn: (data) => async (dispatch) => {
    try {
      dispatch({
        type: ORDER_REQUEST,
      });

      await orderPayLoggedIn(data, `Bearer ${localStorage.access_token}`).then(
        (response) => {
          // console.log('payment success', response)
          dispatch({
            type: ORDER_SUCCESS,
            payload: {
              message: "Transaction Completed Successfully",
              order: response.data.data,
            },
          });
          dispatch(actions.step3Checkout());
          dispatch(actions.emptyCart());
        }
      );
    } catch (error) {
      // console.log('payment error', error.response)
      dispatch({
        type: ORDER_ERROR,
        payload: {
          message: error.response?.data?.message || "Payment Error",
        },
      });
    }
  },

  changeTotalToBePaid: (data) => async (dispatch) => {
    dispatch({
      type: CHANGE_PAYMENT_TOTAL,
      data: data,
    });
  },

  emptyOrderResponse: (data) => async (dispatch) => {
    dispatch({
      type: EMPTY_ORDER_RESPONSE,
    });
  },
};

export default actions;
