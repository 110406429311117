import {
  EVENT_CATEGORIES_REQUEST,
  EVENT_CATEGORIES_SUCCESS,
  EVENT_CATEGORIES_ERROR,
  CATEGORIES_FILTER_REQUEST,
  CATEGORIES_FILTER_SUCCESS,
  CATEGORIES_FILTER_ERROR,
  LOCATIONS_REQUEST,
  LOCATIONS_SUCCESS,
  LOCATIONS_ERROR,
  CATEGORIES_CAROUSEL_FILTER_REQUEST,
  CATEGORIES_CAROUSEL_FILTER_SUCCESS,
  CATEGORIES_CAROUSEL_FILTER_ERROR,
  EVENT_ID_REQUEST,
  EVENT_ID_SUCCESS,
  EVENT_ID_ERROR,
  EVENT_PROGRAM_CHECKED,
  UPCOMING_EVENT_REQUEST,
  UPCOMING_EVENT_SUCCESS,
  UPCOMING_EVENT_ERROR,
  EVENT_PROGRAM_STEPS,
  EVENT_BOOKING_PARENT,
  EVENT_BOOKING_CHILDREN,
  COUPON_REQUEST,
  COUPON_SUCCESS,
  COUPON_ERROR,
  COUPON_RESET,
  EVENT_MAX_SELECTION,
  BOOKING_EVENT_REQUEST,
  BOOKING_EVENT_SUCCESS,
  BOOKING_EVENT_ERROR,
  BOOKING_EVENT_RESETERROR,
  CHANGE_COUPON,
  ACTIVE_VOUCHER_REQUEST,
  ACTIVE_VOUCHER_SUCCESS,
  ACTIVE_VOUCHER_ERROR,
  GO_BACK_PARENT_FIELDS,
  GO_BACK_CHILDREN_FIELDS,
  GO_BACK_STATE,
  VALIDATE_ACTIVE_VOUCHER_REQUEST,
  VALIDATE_ACTIVE_VOUCHER_SUCCESS,
  VALIDATE_ACTIVE_VOUCHER_ERROR,
  VALIDATE_ACTIVE_VOUCHER_RESET,
  SAVE_KID_DETAILS,
  CHANGE_MAX_REACHED,
  IS_EVENT_BOOKING,
  EVENTS_COUNT_REQUEST,
  EVENTS_COUNT_SUCCESS,
  EVENTS_COUNT_ERROR,
  EVENTS_TERMS_COUNT_REQUEST,
  EVENTS_TERMS_COUNT_SUCCESS,
  EVENTS_TERMS_COUNT_ERROR,
  CHANGE_ACTIVE_VOUCHER_KIDS_TOTAL,
  CHANGE_ACTIVE_VOUCHER_KIDS,
} from "../actions/events";

const initialState = {
  loadingCoupon: false,
  loadingBooking: false,
  bookingError: "",
  totalCategories: 0,
  eventCategories: [],
  eventCategoriesLoading: false,
  categoriesFilter: [],
  categoriesFilterLoading: false,
  locations: [],
  // categoriesCarouselFilter: [],
  // categoriesCarouselFilterLoading: false,
  eventId: [],
  eventIdLoading: false,
  eventProgramChecked: [],
  upcomingEvents: [],
  upcomingEventsLoading: false,
  eventProgramSteps: 1,
  eventBookingParent: {},
  eventBookingChildrens: [],
  bookingResponseData: [],
  maxSelection: 1,
  couponCode: "",
  coupon_percentage: null,
  coupon_error_message: "",
  activeVoucher: false,
  activeKidsVoucherTotal: 0,
  activeVoucherKids: [],
  goBackState: false,
  goBackChildrenFields: [],
  goBackParentFields: [],

  validateVoucherCodeLoading: false,
  validateVoucherCode: [],
  validateVoucherCodeError: "",

  kidDetails: [],
  maxReached: [],

  is_booking: false,

  eventsCountPerCategory: 0,
  eventsCountPerTerms: {},
};

function events(state = initialState, action) {
  switch (action.type) {
    case EVENT_MAX_SELECTION:
      return Object.assign({}, state, {
        maxSelection: action.payload.data,
      });

    case EVENT_CATEGORIES_REQUEST:
      return Object.assign({}, state, {
        eventCategoriesLoading: true,
        eventCategories: [],
      });
    case EVENT_CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        eventCategoriesLoading: false,
        eventCategories: action.payload.data,
        totalCategories: action.payload.total,
      });
    case EVENT_CATEGORIES_ERROR:
      return Object.assign({}, state, {
        eventCategoriesLoading: false,
        eventCategories: [],
      });

    case BOOKING_EVENT_RESETERROR:
      return Object.assign({}, state, {
        bookingError: "",
      });
    case BOOKING_EVENT_REQUEST:
      return Object.assign({}, state, {
        loadingBooking: true,
        bookingError: "",
        bookingResponseData: [],
      });
    case BOOKING_EVENT_SUCCESS:
      return Object.assign({}, state, {
        loadingBooking: false,
        bookingError: "",
        eventBookingParent: {},
        eventBookingChildrens: [],
        eventProgramChecked: [],
        couponCode: "",
        coupon_percentage: null,
        bookingResponseData: action.payload?.data,
      });
    case BOOKING_EVENT_ERROR:
      return Object.assign({}, state, {
        loadingBooking: false,
        bookingError: action.payload.message,
        // couponCode: "",
        // coupon_percentage: null,
        bookingResponseData: [],
      });
    case CATEGORIES_FILTER_REQUEST:
      return Object.assign({}, state, {
        categoriesFilterLoading: true,
        categoriesFilter: [],
      });
    case CATEGORIES_FILTER_SUCCESS:
      return Object.assign({}, state, {
        categoriesFilterLoading: false,
        categoriesFilter: action.payload.data,
      });
    case CATEGORIES_FILTER_ERROR:
      return Object.assign({}, state, {
        categoriesFilterLoading: false,
        categoriesFilter: [],
      });

    case LOCATIONS_REQUEST:
      return Object.assign({}, state, {
        locations: [],
      });
    case LOCATIONS_SUCCESS:
      return Object.assign({}, state, {
        locations: action.payload.data,
      });
    case LOCATIONS_ERROR:
      return Object.assign({}, state, {
        locations: [],
      });

    case CATEGORIES_CAROUSEL_FILTER_REQUEST:
      return Object.assign({}, state, {
        upcomingEventsLoading: true,
        upcomingEvents: [],
      });
    case CATEGORIES_CAROUSEL_FILTER_SUCCESS:
      return Object.assign({}, state, {
        upcomingEventsLoading: false,
        upcomingEvents: action.payload.data,
      });
    case CATEGORIES_CAROUSEL_FILTER_ERROR:
      return Object.assign({}, state, {
        upcomingEventsLoading: false,
        upcomingEvents: [],
      });

    case EVENT_ID_REQUEST:
      return Object.assign({}, state, {
        eventId: [],
        eventIdLoading: true,
      });
    case EVENT_ID_SUCCESS:
      return Object.assign({}, state, {
        eventId: action.payload.data,
        eventIdLoading: false,
      });
    case EVENT_ID_ERROR:
      return Object.assign({}, state, {
        eventId: [],
        eventIdLoading: false,
      });

    case UPCOMING_EVENT_REQUEST:
      return Object.assign({}, state, {
        upcomingEvents: [],
        upcomingEventsLoading: true,
      });
    case UPCOMING_EVENT_SUCCESS:
      return Object.assign({}, state, {
        upcomingEvents: action.payload.data,
        upcomingEventsLoading: false,
      });
    case UPCOMING_EVENT_ERROR:
      return Object.assign({}, state, {
        upcomingEvents: [],
        upcomingEventsLoading: false,
      });

    case EVENT_PROGRAM_CHECKED:
      return Object.assign({}, state, {
        eventProgramChecked: action.payload.data,
      });

    case EVENT_PROGRAM_STEPS:
      return Object.assign({}, state, {
        eventProgramSteps: action.payload.data,
      });

    case IS_EVENT_BOOKING:
      return Object.assign({}, state, {
        is_booking: action.payload.data,
      });

    case EVENT_BOOKING_PARENT:
      return Object.assign({}, state, {
        eventBookingParent: action.payload.data,
      });

    case EVENT_BOOKING_CHILDREN:
      return Object.assign({}, state, {
        eventBookingChildrens: action.payload.data,
      });

    case CHANGE_COUPON:
      return Object.assign({}, state, {
        couponCode: action.payload.data,
      });

    case COUPON_REQUEST:
      return Object.assign({}, state, {
        loadingCoupon: true,
        coupon_error_message: "",
      });

    case COUPON_SUCCESS:
      return Object.assign({}, state, {
        loadingCoupon: false,
        coupon_percentage: action.payload.value,
        coupon_error_message: "",
      });

    case COUPON_ERROR:
      return Object.assign({}, state, {
        loadingCoupon: false,
        coupon_percentage: null,
        coupon_error_message: action.payload.message,
      });

    case COUPON_RESET:
      return Object.assign({}, state, {
        coupon_percentage: null,
        coupon_error_message: "",
        couponCode: "",
      });

    case ACTIVE_VOUCHER_REQUEST:
      return Object.assign({}, state, {
        activeVoucher: false,
      });

    case ACTIVE_VOUCHER_SUCCESS:
      return Object.assign({}, state, {
        activeVoucher: action.payload.data,
      });

    case ACTIVE_VOUCHER_ERROR:
      return Object.assign({}, state, {
        activeVoucher: false,
      });

    case GO_BACK_CHILDREN_FIELDS:
      return Object.assign({}, state, {
        goBackChildrenFields: action.payload,
      });
    case GO_BACK_PARENT_FIELDS:
      return Object.assign({}, state, {
        goBackParentFields: action.payload,
      });
    case GO_BACK_STATE:
      return Object.assign({}, state, {
        goBackState: action.payload,
      });

    case VALIDATE_ACTIVE_VOUCHER_REQUEST:
      return Object.assign({}, state, {
        validateVoucherCodeLoading: [true, action.payload],
        validateVoucherCode: [],
        validateVoucherCodeError: "",
      });

    case VALIDATE_ACTIVE_VOUCHER_SUCCESS:
      return Object.assign({}, state, {
        validateVoucherCodeLoading: false,
        validateVoucherCode: action.payload.data,
        validateVoucherCodeError: "",
      });

    case VALIDATE_ACTIVE_VOUCHER_ERROR:
      return Object.assign({}, state, {
        validateVoucherCodeLoading: false,
        validateVoucherCode: [],
        validateVoucherCodeError: action.payload.message,
      });

    case VALIDATE_ACTIVE_VOUCHER_RESET:
      return Object.assign({}, state, {
        validateVoucherCodeLoading: false,
        validateVoucherCode: [],
        validateVoucherCodeError: "",
      });

    case SAVE_KID_DETAILS:
      return Object.assign({}, state, {
        kidDetails: action.data,
      });

    case CHANGE_MAX_REACHED:
      return Object.assign({}, state, {
        maxReached: action.data,
      });

    case EVENTS_COUNT_REQUEST:
      return Object.assign({}, state, {
        eventsCountPerCategory: 0,
      });

    case EVENTS_COUNT_SUCCESS:
      return Object.assign({}, state, {
        eventsCountPerCategory: action.payload.data,
      });

    case EVENTS_COUNT_ERROR:
      return Object.assign({}, state, {
        eventsCountPerCategory: 0,
      });

    case EVENTS_TERMS_COUNT_REQUEST:
      return Object.assign({}, state, {
        eventsCountPerTerms: null,
      });

    case EVENTS_TERMS_COUNT_SUCCESS:
      return Object.assign({}, state, {
        eventsCountPerTerms: action.payload.data,
      });

    case EVENTS_TERMS_COUNT_ERROR:
      return Object.assign({}, state, {
        eventsCountPerTerms: null,
      });

    case CHANGE_ACTIVE_VOUCHER_KIDS_TOTAL:
      return Object.assign({}, state, {
        activeKidsVoucherTotal: action.data,
      });

    case CHANGE_ACTIVE_VOUCHER_KIDS:
      return Object.assign({}, state, {
        activeVoucherKids: action.data,
      });

    default:
      return state;
  }
}

export default events;
