import {
    LOAD_HOME_PRODUCTS_REQUEST, LOAD_HOME_PRODUCTS_SUCCESS, LOAD_HOME_PRODUCTS_ERROR,
    LOAD_PRODUCT_DETAILS_REQUEST, LOAD_PRODUCT_DETAILS_SUCCESS, LOAD_PRODUCT_DETAILS_ERROR,
    LOAD_PRODUCTS_COLLECTION_FILTER_REQUEST, LOAD_PRODUCTS_COLLECTION_FILTER_SUCCESS, LOAD_PRODUCTS_COLLECTION_FILTER_ERROR,
    LOAD_PRODUCT_CATEGORIES_REQUEST, LOAD_PRODUCT_CATEGORIES_SUCCESS,LOAD_PRODUCT_CATEGORIES_ERROR,
    LOAD_CATEGORY_DETAILS_REQUEST, LOAD_CATEGORY_DETAILS_SUCCESS, LOAD_CATEGORY_DETAILS_ERROR
} from '../actions/store';

const initialState = {
    homeProducts: [],
    homeProductsLoading: false,
    productDetails: [],
    productDetailsLoading: false,
    productsCollectionFilter: [],
    productsCollectionFilterLoading: false,
    ProductCategories:[],
    ProductCategoriesLoading:false,
    categoryDetails: [],
};

function store(state = initialState, action) {
    switch (action.type) {
        case LOAD_HOME_PRODUCTS_REQUEST:
            return Object.assign({}, state, {
                homeProductsLoading: true,
                homeProducts: [],
            });

        case LOAD_HOME_PRODUCTS_SUCCESS:
            return Object.assign({}, state, {
                homeProductsLoading: false,
                homeProducts: action.payload.data,
            });

        case LOAD_HOME_PRODUCTS_ERROR:
            return Object.assign({}, state, {
                homeProductsLoading: false,
                homeProducts: [],
            });


        case LOAD_PRODUCT_DETAILS_REQUEST:
            return Object.assign({}, state, {
                productDetailsLoading: true,
                productDetails: [],
            });
        case LOAD_PRODUCT_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                productDetailsLoading: false,
                productDetails: action.payload.data,
            });
        case LOAD_PRODUCT_DETAILS_ERROR:
            return Object.assign({}, state, {
                productDetailsLoading: false,
                productDetails: [],
            });


        case LOAD_PRODUCTS_COLLECTION_FILTER_REQUEST:
            return Object.assign({}, state, {
                productsCollectionFilterLoading: true,
                productsCollectionFilter: [],
            });
        case LOAD_PRODUCTS_COLLECTION_FILTER_SUCCESS:
            return Object.assign({}, state, {
                productsCollectionFilterLoading: false,
                productsCollectionFilter: action.payload.data,
            });
        case LOAD_PRODUCTS_COLLECTION_FILTER_ERROR:
            return Object.assign({}, state, {
                productsCollectionFilterLoading: false,
                productsCollectionFilter: [],
            });
        case LOAD_PRODUCT_CATEGORIES_REQUEST:
            return Object.assign({},state,{
                ProductCategoriesLoading:true,
                ProductCategories:[],
            });
        case LOAD_PRODUCT_CATEGORIES_SUCCESS:
            return Object.assign({},state,{
                ProductCategoriesLoading:false,
                ProductCategories:action.payload.data,
            });
        case LOAD_PRODUCT_CATEGORIES_ERROR:
            return Object.assign({},state,{
                ProductCategoriesLoading:false,
                ProductCategories:[],
            });
        case LOAD_CATEGORY_DETAILS_REQUEST:
            return Object.assign({},state,{
                categoryDetails:[],
            });
        case LOAD_CATEGORY_DETAILS_SUCCESS:
            return Object.assign({},state,{
                categoryDetails:action?.payload?.data,
            });
        case LOAD_CATEGORY_DETAILS_ERROR:
            return Object.assign({},state,{
                categoryDetails:[],
            });


        default:
            return state;
    }
}

export default store;


