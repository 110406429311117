import React from 'react';
// import { Spinner } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactDelayRender from 'react-delay-render';

const Loading = () => (
	<div
		style={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			marginTop: 24,
		}}
	>
		<CircularProgress color='primary' />
		{/* <Spinner style={{ width: '5rem', height: '5rem', color: '#44bd32' }} type="grow" /> */}
		<h1
			style={{
				textAlign: 'center',
				fontSize: 18,
				marginTop: 8,
				opacity: '50%',
				fontWeight: 400,
			}}
		>
			Loading
		</h1>
	</div>
);

export default ReactDelayRender({ delay: 300 })(Loading);
