import {
    GET_CAROUSEL_ITEM_REQUEST,
    GET_CAROUSEL_ITEM_SUCCESS,
    GET_CAROUSEL_ITEM_ERROR,
} from '../actions/home';



const initialState = {
	getCarouselItemsData:[],
    getCarouselItemsLoading:false,
};

function home(state = initialState, action) {
	switch (action.type) {
		case GET_CAROUSEL_ITEM_REQUEST:
			return Object.assign({}, state, {
				getCarouselItemsLoading: true,
				getCarouselItemsData: [],
			});
		case GET_CAROUSEL_ITEM_SUCCESS:
			return Object.assign({}, state, {
				getCarouselItemsLoading: false,
				getCarouselItemsData: action.payload.data,
			});
		case GET_CAROUSEL_ITEM_ERROR:
			return Object.assign({}, state, {
				getCarouselItemsLoading: false,
				getCarouselItemsData: [],
			});

		default:
			return state;
	}
}

export default home;
