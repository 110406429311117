import {
  LoadEventCategories,
  LoadEventByCategoryWithFilter,
  LoadCategoryLocations,
  LoadEventByID,
  LoadUpcomingEvents,
  getCouponOffer,
  bookingEvent,
  getSetingsById,
  bookingEventUser,
  validateVoucher,
  editDetails,
  loadEventsCountPerCategory,
  loadEventsCountPerTerms,
} from "../../config/config";

import { toast } from "react-toastify";
//import { configureStore, configurePersistor} from "../store/store";

export const EVENT_CATEGORIES_REQUEST = "EVENT_CATEGORIES_REQUEST";
export const EVENT_CATEGORIES_SUCCESS = "EVENT_CATEGORIES_SUCCESS";
export const EVENT_CATEGORIES_ERROR = "EVENT_CATEGORIES_ERROR";

export const CATEGORIES_FILTER_REQUEST = "CATEGORIES_FILTER_REQUEST";
export const CATEGORIES_FILTER_SUCCESS = "CATEGORIES_FILTER_SUCCESS";
export const CATEGORIES_FILTER_ERROR = "CATEGORIES_FILTER_ERROR";

export const LOCATIONS_REQUEST = "LOCATIONS_REQUEST";
export const LOCATIONS_SUCCESS = "LOCATIONS_SUCCESS";
export const LOCATIONS_ERROR = "LOCATIONS_ERROR";

export const CATEGORIES_CAROUSEL_FILTER_REQUEST =
  "CATEGORIES_CAROUSEL_FILTER_REQUEST";
export const CATEGORIES_CAROUSEL_FILTER_SUCCESS =
  "CATEGORIES_CAROUSEL_FILTER_SUCCESS";
export const CATEGORIES_CAROUSEL_FILTER_ERROR =
  "CATEGORIES_CAROUSEL_FILTER_ERROR";

export const EVENT_ID_REQUEST = "EVENT_ID_REQUEST";
export const EVENT_ID_SUCCESS = "EVENT_ID_SUCCESS";
export const EVENT_ID_ERROR = "EVENT_ID_ERROR";

export const UPCOMING_EVENT_REQUEST = "UPCOMING_EVENT_REQUEST";
export const UPCOMING_EVENT_SUCCESS = "UPCOMING_EVENT_SUCCESS";
export const UPCOMING_EVENT_ERROR = "UPCOMING_EVENT_ERROR";

export const EVENT_PROGRAM_CHECKED = "EVENT_PROGRAM_CHECKED";

export const EVENT_PROGRAM_STEPS = "EVENT_PROGRAM_STEPS";

export const EVENT_BOOKING_PARENT = "EVENT_BOOKING_PARENT";

export const EVENT_BOOKING_CHILDREN = "EVENT_BOOKING_CHILDREN";

export const COUPON_REQUEST = "COUPON_REQUEST";
export const COUPON_SUCCESS = "COUPON_SUCCESS";
export const COUPON_ERROR = "COUPON_ERROR";
export const COUPON_RESET = "COUPON_RESET";
export const CHANGE_COUPON = "CHANGE_COUPON";

export const BOOKING_EVENT_REQUEST = "BOOKING_EVENT_REQUEST";
export const BOOKING_EVENT_SUCCESS = "BOOKING_EVENT_SUCCESS";
export const BOOKING_EVENT_ERROR = "BOOKING_EVENT_ERROR";
export const BOOKING_EVENT_RESETERROR = "BOOKING_EVENT_RESETERROR";

export const EVENT_MAX_SELECTION = "EVENT_MAX_SELECTION";

export const ACTIVE_VOUCHER_REQUEST = "ACTIVE_VOUCHER_REQUEST";
export const ACTIVE_VOUCHER_SUCCESS = "ACTIVE_VOUCHER_SUCCESS";
export const ACTIVE_VOUCHER_ERROR = "ACTIVE_VOUCHER_ERROR";

export const VALIDATE_ACTIVE_VOUCHER_REQUEST =
  "VALIDATE_ACTIVE_VOUCHER_REQUEST";
export const VALIDATE_ACTIVE_VOUCHER_SUCCESS =
  "VALIDATE_ACTIVE_VOUCHER_SUCCESS";
export const VALIDATE_ACTIVE_VOUCHER_ERROR = "VALIDATE_ACTIVE_VOUCHER_ERROR";
export const VALIDATE_ACTIVE_VOUCHER_RESET = "VALIDATE_ACTIVE_VOUCHER_RESET";

export const GO_BACK_CHILDREN_FIELDS = "GO_BACK_CHILDREN_FIELDS";
export const GO_BACK_PARENT_FIELDS = "GO_BACK_PARENT_FIELDS";
export const GO_BACK_STATE = "GO_BACK_STATE";

export const SAVE_KID_DETAILS = "SAVE_PROGRAM_DATES";

export const CHANGE_MAX_REACHED = "CHANGE_MAX_REACHED";

export const IS_EVENT_BOOKING = "IS_EVENT_BOOKING";

export const EVENTS_COUNT_REQUEST = "EVENTS_COUNT_REQUEST";
export const EVENTS_COUNT_SUCCESS = "EVENTS_COUNT_SUCCESS";
export const EVENTS_COUNT_ERROR = "EVENTS_COUNT_ERROR";

export const EVENTS_TERMS_COUNT_REQUEST = "EVENTS_TERMS_COUNT_REQUEST";
export const EVENTS_TERMS_COUNT_SUCCESS = "EVENTS_TERMS_COUNT_SUCCESS";
export const EVENTS_TERMS_COUNT_ERROR = "EVENTS_TERMS_COUNT_ERROR";

export const CHANGE_ACTIVE_VOUCHER_KIDS_TOTAL =
  "CHANGE_ACTIVE_VOUCHER_KIDS_TOTAL";
export const CHANGE_ACTIVE_VOUCHER_KIDS = "CHANGE_ACTIVE_VOUCHER_KIDS";

const actions = {
  eventCategories: (data) => async (dispatch) => {
    try {
      dispatch({
        type: EVENT_CATEGORIES_REQUEST,
      });

      await LoadEventCategories(data).then((response) => {
        if (response.data.code === 200) {
          dispatch({
            type: EVENT_CATEGORIES_SUCCESS,
            payload: {
              data: response.data.data.categories,
              total: response.data.data.total,
            },
          });
        } else {
          dispatch({
            type: EVENT_CATEGORIES_ERROR,
            payload: {
              message: response.message,
            },
          });
        }
      });
    } catch (error) {
      dispatch({
        type: EVENT_CATEGORIES_ERROR,
        payload: {
          message: "Error Loading Event Categories",
        },
      });
    }
  },

  categoriesWithFilter: (data) => async (dispatch) => {
    try {
      dispatch({
        type: CATEGORIES_FILTER_REQUEST,
      });

      await LoadEventByCategoryWithFilter(data).then((response) => {
        // console.log('RESPONSE LOAD', response?.data?.data)
        if (response?.data?.code === 200) {
          dispatch({
            type: CATEGORIES_FILTER_SUCCESS,
            payload: {
              data: response?.data?.data,
            },
          });
        } else {
          dispatch({
            type: CATEGORIES_FILTER_ERROR,
            payload: {
              message: response?.message,
            },
          });
        }
      });
    } catch (error) {
      dispatch({
        type: CATEGORIES_FILTER_ERROR,
        payload: {
          message: "Error Loading Event Categories",
        },
      });
    }
  },

  getEventsCountPerCategory: (data) => async (dispatch) => {
    try {
      dispatch({
        type: EVENTS_COUNT_REQUEST,
      });

      await loadEventsCountPerCategory(data).then((response) => {
        if (response?.data?.code === 200) {
          dispatch({
            type: EVENTS_COUNT_SUCCESS,
            payload: {
              data: response?.data?.data,
            },
          });
        } else {
          dispatch({
            type: EVENTS_COUNT_ERROR,
          });
        }
      });
    } catch (error) {
      dispatch({
        type: EVENTS_COUNT_ERROR,
      });
    }
  },

  getEventsCountPerTerms: (data) => async (dispatch) => {
    try {
      dispatch({
        type: EVENTS_TERMS_COUNT_REQUEST,
      });

      await loadEventsCountPerTerms(data).then((response) => {
        if (response?.data?.code === 200) {
          dispatch({
            type: EVENTS_TERMS_COUNT_SUCCESS,
            payload: {
              data: response?.data?.data,
            },
          });
        } else {
          dispatch({
            type: EVENTS_TERMS_COUNT_ERROR,
          });
        }
      });
    } catch (error) {
      dispatch({
        type: EVENTS_TERMS_COUNT_ERROR,
      });
    }
  },

  getCategoryLocations: (data) => async (dispatch) => {
    try {
      dispatch({
        type: LOCATIONS_REQUEST,
      });

      await LoadCategoryLocations(data).then((response) => {
        if (response?.data?.code === 200) {
          dispatch({
            type: LOCATIONS_SUCCESS,
            payload: {
              data: response?.data?.data,
            },
          });
        } else {
          dispatch({
            type: LOCATIONS_ERROR,
            payload: {
              message: response?.message,
            },
          });
        }
      });
    } catch (error) {
      dispatch({
        type: LOCATIONS_ERROR,
        payload: {
          message: "Error Loading Category Locations",
        },
      });
    }
  },

  categoriesWithFilterCarousel: (data) => async (dispatch) => {
    try {
      dispatch({
        type: CATEGORIES_CAROUSEL_FILTER_REQUEST,
      });

      await LoadEventByCategoryWithFilter(data).then((response) => {
        if (response.data.code === 200) {
          dispatch({
            type: CATEGORIES_CAROUSEL_FILTER_SUCCESS,
            payload: {
              data: response.data.data,
            },
          });
        } else {
          dispatch({
            type: CATEGORIES_CAROUSEL_FILTER_ERROR,
            payload: {
              message: response.message,
            },
          });
        }
      });
    } catch (error) {
      dispatch({
        type: CATEGORIES_CAROUSEL_FILTER_ERROR,
        payload: {
          message: "Error Loading Event Categories",
        },
      });
    }
  },

  eventById: (data) => async (dispatch) => {
    try {
      dispatch({
        type: EVENT_ID_REQUEST,
      });

      await LoadEventByID(data).then((response) => {
        if (response.data.code === 200) {
          dispatch({
            type: EVENT_ID_SUCCESS,
            payload: {
              data: response.data.data,
            },
          });
        } else {
          dispatch({
            type: EVENT_ID_ERROR,
            payload: {
              message: response.message,
            },
          });
        }
      });
    } catch (error) {
      dispatch({
        type: EVENT_ID_ERROR,
        payload: {
          message: "Error Loading Event Categories",
        },
      });
    }
  },

  upcomingEvents: (data) => async (dispatch) => {
    try {
      dispatch({
        type: UPCOMING_EVENT_REQUEST,
      });

      await LoadUpcomingEvents(data).then((response) => {
        if (response.data.code === 200) {
          dispatch({
            type: UPCOMING_EVENT_SUCCESS,
            payload: {
              data: response.data.data,
            },
          });
        } else {
          dispatch({
            type: UPCOMING_EVENT_ERROR,
            payload: {
              message: response.message,
            },
          });
        }
      });
    } catch (error) {
      dispatch({
        type: UPCOMING_EVENT_ERROR,
        payload: {
          message: "Error Loading Upcoming Events",
        },
      });
    }
  },

  eventProgramChecked: (data) => (dispatch) => {
    dispatch({
      type: EVENT_PROGRAM_CHECKED,
      payload: {
        data: data,
      },
    });
  },

  setMaxProgSelec: (num) => (dispatch) => {
    dispatch({
      type: EVENT_MAX_SELECTION,
      payload: {
        data: num,
      },
    });
  },

  eventProgramSteps: (data) => (dispatch) => {
    dispatch({
      type: EVENT_PROGRAM_STEPS,
      payload: {
        data: data,
      },
    });
  },

  changeIsEventBooking: (data) => (dispatch) => {
    dispatch({
      type: IS_EVENT_BOOKING,
      payload: {
        data: data,
      },
    });
  },

  eventBookingParent: (data) => (dispatch) => {
    dispatch({
      type: EVENT_BOOKING_PARENT,
      payload: {
        data: data,
      },
    });
  },

  eventBookingChildren: (data) => (dispatch) => {
    dispatch({
      type: EVENT_BOOKING_CHILDREN,
      payload: {
        data: data,
      },
    });
  },

  setCoupon: (data) => (dispatch) => {
    if (localStorage.getItem("coupon")) {
      localStorage.removeItem("coupon");
      localStorage.setItem("coupon", data);
    } else {
      localStorage.setItem("coupon", data);
    }

    dispatch({
      type: CHANGE_COUPON,
      payload: {
        data: data,
      },
    });
  },

  getCoupon: (code, user) => async (dispatch) => {
    try {
      dispatch({
        type: COUPON_REQUEST,
      });

      await getCouponOffer(code, user).then((response) => {
        if (response.data.code === 200) {
          // localStorage.setItem('coupon_percentage', response.data.data.value)
          dispatch({
            type: COUPON_SUCCESS,
            payload: {
              value: response.data?.data?.value,
              coupon_id: response.data?.data?.id,
            },
          });
        } else {
          // toast.error(response?.data?.message)
          dispatch({
            type: COUPON_ERROR,
            payload: {
              message: response?.data?.message || "Coupon Expired",
            },
          });
        }
      });
    } catch (error) {
      // console.log('COUPON ERROR', error.response)
      // toast.error(error.response.data.message)
      dispatch({
        type: COUPON_ERROR,
        payload: {
          message: error.response.data.message || "Coupon Expired",
        },
      });
    }
  },

  getCoupon2: (response, type) => (dispatch) => {
    if (type === 1) {
      dispatch({
        type: COUPON_REQUEST,
      });
    } else if (type === 2) {
      dispatch({
        type: COUPON_SUCCESS,
        payload: {
          value: response.data.data.value,
          coupon_id: response.data.data.id,
        },
      });
    } else {
      dispatch({
        type: COUPON_ERROR,
        payload: {
          message: "Coupon Invalid",
        },
      });
    }
  },

  resetCouponCode: () => (dispatch) => {
    dispatch({
      type: COUPON_RESET,
    });
  },

  bookEvent: (data, history) => async (dispatch) => {
    try {
      dispatch({
        type: BOOKING_EVENT_REQUEST,
      });
      let coupon = "";
      if (localStorage.getItem("coupon")) {
        coupon = localStorage.getItem("coupon");
        data.coupon_code = coupon;
      }

      await bookingEvent(data).then((response) => {
        // console.log('booking event response', response)
        if (response.data.code === 200) {
          dispatch({
            type: BOOKING_EVENT_SUCCESS,
            payload: {
              data: response.data.data,
            },
          });
          window.dataLayer.push({
            event: "transaction",
            ecommerce: {
              purchase: {
                actionField: {
                  id: response.data.data.event_details
                    .booking_payment_transaction_id, // Transaction ID. Required for purchases and refunds.
                  affiliation: "Online Store",
                  revenue:
                    response.data.data.event_details
                      .booking_payment_total_dollar, // Total transaction value (incl. tax and shipping)
                },
                products: [
                  {
                    name: response.data.data.event_details.program,
                    price: response.data.data.event_details.program_price,
                    quantity:
                      response.data.data.event_details
                        .booking_payment_total_dollar /
                      response.data.data.event_details.program_price,
                  },
                ],
              },
            },
          });
          history.push("/events/booking-success");
        } else {
          dispatch({
            type: BOOKING_EVENT_ERROR,
            payload: {
              message: "Booking Error Please try again",
            },
          });
        }
      });
    } catch (error) {
      // console.log('ERROR BOOKING', error.response)
      dispatch({
        type: BOOKING_EVENT_ERROR,
        payload: {
          message: error.response.data
            ? error.response.data.message
            : "Server Error, Please try again",
        },
      });
    }
  },

  updateAndBookUser: (data, history, dataToUpdate) => async (dispatch) => {
    try {
      dispatch({
        type: BOOKING_EVENT_REQUEST,
      });
      await editDetails(
        dataToUpdate,
        "Bearer" + localStorage.access_token
      ).then((response) => {
        if (response.data.code === 200) {
          dispatch(actions.bookEventUser(data, history));
        }
      });
    } catch (error) {
      // console.log('ERROR BOOKING', error.response)
      dispatch({
        type: BOOKING_EVENT_ERROR,
        payload: {
          message: error.response.data
            ? error.response.data.message
            : "Server Error, Please try again",
        },
      });
    }
  },

  bookEventUser: (data, history) => async (dispatch) => {
    try {
      dispatch({
        type: BOOKING_EVENT_REQUEST,
      });
      let coupon = "";
      if (localStorage.getItem("coupon")) {
        coupon = localStorage.getItem("coupon");
        data.coupon_code = coupon;
      }

      // console.log('booking', data)
      await bookingEventUser(data, "Bearer " + localStorage.access_token).then(
        (response) => {
          // console.log('booking event response', response)
          if (response.data.code === 200) {
            dispatch({
              type: BOOKING_EVENT_SUCCESS,
              payload: {
                data: response.data.data,
              },
            });
            window.dataLayer.push({
              event: "transaction",
              ecommerce: {
                purchase: {
                  actionField: {
                    id: response.data.data.event_details
                      .booking_payment_transaction_id, // Transaction ID. Required for purchases and refunds.
                    affiliation: "Online Store",
                    revenue:
                      response.data.data.event_details
                        .booking_payment_total_dollar, // Total transaction value (incl. tax and shipping)
                  },
                  products: [
                    {
                      name: response.data.data.event_details.program,
                      price: response.data.data.event_details.program_price,
                      quantity:
                        response.data.data.event_details
                          .booking_payment_total_dollar /
                        response.data.data.event_details.program_price,
                    },
                  ],
                },
              },
            });
            history.push("/events/booking-success");
          } else {
            dispatch({
              type: BOOKING_EVENT_ERROR,
              payload: {
                message: "Booking Error Please try again",
              },
            });
          }
        }
      );
    } catch (error) {
      // console.log('ERROR BOOKING', error.response)
      dispatch({
        type: BOOKING_EVENT_ERROR,
        payload: {
          message: error.response.data
            ? error.response.data.message
            : "Server Error, Please try again",
        },
      });
    }
  },

  resetParentChild: () => async (dispatch) => {
    dispatch({
      type: BOOKING_EVENT_SUCCESS,
    });
  },

  resetError: () => async (dispatch) => {
    dispatch({
      type: BOOKING_EVENT_RESETERROR,
    });
  },

  // getActiveVouchers: () => async (dispatch) => {
  //     try {
  //         dispatch({
  //             type: ACTIVE_VOUCHER_REQUEST,
  //         })

  //         await getSetingsById().then((response) => {
  //             if (response.data.code === 200) {
  //                 // console.log('active voucher resp', response.data)
  //                 dispatch({
  //                     type: ACTIVE_VOUCHER_SUCCESS,
  //                     payload: {
  //                         data: response?.data?.data === 1 ? true : false,
  //                     },
  //                 });
  //             } else {
  //                 dispatch({
  //                     type: ACTIVE_VOUCHER_ERROR,
  //                 });
  //             }
  //         })
  //     } catch (error) {
  //         dispatch({
  //             type: ACTIVE_VOUCHER_ERROR,
  //         });
  //     }
  // },

  handleGoBackChildren: (data) => async (dispatch) => {
    dispatch({
      type: GO_BACK_CHILDREN_FIELDS,
      payload: data,
    });
  },
  handleGoBackParent: (data) => async (dispatch) => {
    dispatch({
      type: GO_BACK_PARENT_FIELDS,
      payload: data,
    });
  },
  handleGoBackState: (data) => async (dispatch) => {
    dispatch({
      type: GO_BACK_STATE,
      payload: data,
    });
  },

  validateActiveVoucher: (data) => async (dispatch) => {
    try {
      let childName = data?.children_first_name;
      dispatch({
        type: VALIDATE_ACTIVE_VOUCHER_REQUEST,
        payload: childName,
      });
      await validateVoucher(
        data,
        "Bearer " + localStorage.getItem("access_token")
      ).then((response) => {
        if (response.data.code === 200) {
          // console.log('validate active voucher resp', response.data)
          dispatch({
            type: VALIDATE_ACTIVE_VOUCHER_SUCCESS,
            payload: {
              data: [response.data.data, childName],
            },
          });
        } else {
          // toast.error(response.data.message)
          dispatch({
            type: VALIDATE_ACTIVE_VOUCHER_ERROR,
            payload: {
              message: response?.data?.message || "Coupon Invalid",
            },
          });
        }
      });
    } catch (error) {
      // toast.error(error.response.data.message)
      dispatch({
        type: VALIDATE_ACTIVE_VOUCHER_ERROR,
        payload: {
          message: error?.response?.data?.message || "Coupon Invalid",
        },
      });
    }
  },

  saveKidDetails: (data) => (dispatch) => {
    dispatch({
      type: SAVE_KID_DETAILS,
      data: data,
    });
  },

  changeMaxReached: (data) => (dispatch) => {
    dispatch({
      type: CHANGE_MAX_REACHED,
      data: data,
    });
  },

  resetVoucherCode: () => (dispatch) => {
    dispatch({
      type: VALIDATE_ACTIVE_VOUCHER_RESET,
    });
  },

  changeActiveVoucherKidTotal: (data) => (dispatch) => {
    dispatch({
      type: CHANGE_ACTIVE_VOUCHER_KIDS_TOTAL,
      data: data,
    });
  },

  changeActiveVoucherKids: (data) => (dispatch) => {
    dispatch({
      type: CHANGE_ACTIVE_VOUCHER_KIDS,
      data: data,
    });
  },
};

export default actions;
