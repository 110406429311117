import {
    LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_ERROR,
    FORGOTPASS_REQUEST, FORGOTPASS_SUCCESS, FORGOTPASS_ERROR, REGISTER_ERROR, REGISTER_REQUEST, REGISTER_SUCCESS, CREATEPASS_REQUEST, CREATEPASS_SUCCESS,
    CREATEPASS_ERROR, REGISTERLOGIN_REQUEST, REGISTERLOGIN_SUCCESS, REGISTERLOGIN_ERROR, VERIFYUSER_SUCCESS, VERIFYUSER_REQUEST, VERIFYUSER_ERROR
} from '../actions/auth';

const initialState = {
    loading: false,
    errorMessage: '',
    token: localStorage?.access_token,
    isLoggedIn: false,
    forgotMessage: '',
    forgotPassLoading: false,
    registerLoginLoading: false,
    verifyLoading: false,
    isVerified: false,
    loginLoading: false,
    isReset: false,
    createPassLoading: false,
    registerLoading: false,
    accountExist: '',
    existingEmail: '',
    email: '',
    name: localStorage?.name,
};

function auth(state = initialState, action) {
    switch (action.type) {
        case REGISTERLOGIN_REQUEST:
            return Object.assign({}, state, {
                registerLoginLoading: true,
            });
        case REGISTERLOGIN_SUCCESS:
            return Object.assign({}, state, {
                registerLoginLoading: false,
                accountExist: action.payload.data,
                existingEmail: action.payload.existEmail,
            });
        case REGISTERLOGIN_ERROR:
            return Object.assign({}, state, {
                registerLoginLoading: false,
                accountExist: action.payload.message,
                existingEmail: '',
            });
        case LOGIN_REQUEST:
            return Object.assign({}, state, {
                loginLoading: true,
                errorMessage: '',
                token: '',
                email: '',
                name: '',
            });
        case LOGIN_SUCCESS:
            return Object.assign({}, state, {
                loginLoading: false,
                token: action.payload.data?.access_token,
                email: action.payload.data?.email,
                name: action.payload.data?.first_name,
                isLoggedIn: true,
                errorMessage: '',
            });
        case LOGIN_ERROR:
            return Object.assign({}, state, {
                loginLoading: false,
                isLoggedIn: false,
                token: '',
                email: '',
                name: '',
                errorMessage: action.payload?.message
            });

        case LOGOUT_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                errorMessage: '',
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                loginLoading: false,
                token: '',
                email: '',
                name: '',
                isLoggedIn: false,
                errorMessage: '',
            });
        case LOGOUT_ERROR:
            return Object.assign({}, state, {
                loading: false,
                token: '',
                email: '',
                name: '',
                isLoggedIn: false,
                errorMessage: action.payload.message
            });

        case FORGOTPASS_REQUEST:
            return Object.assign({}, state, {
                forgotPassLoading: true,
                forgotMessage: '',
            });
        case FORGOTPASS_SUCCESS:
            return Object.assign({}, state, {
                forgotPassLoading: false,
                forgotMessage: '',
            });
        case FORGOTPASS_ERROR:
            return Object.assign({}, state, {
                forgotPassLoading: false,
                forgotMessage: action.payload.message,
            });

        case REGISTER_REQUEST:
            return Object.assign({}, state, {
                registerLoading: true,
                errorMessage: '',
            });
        case REGISTER_SUCCESS:
            return Object.assign({}, state, {
                registerLoading: false,
                // isLoggedIn: true,
                errorMessage: '',
            });
        case REGISTER_ERROR:
            return Object.assign({}, state, {
                registerLoading: false,
                // isLoggedIn: false,
                errorMessage: action.payload.message
            });
        case CREATEPASS_REQUEST:
            return Object.assign({}, state, {
                createPassLoading: true,
                errorMessage: '',
            });
        case CREATEPASS_SUCCESS:
            return Object.assign({}, state, {
                createPassLoading: false,
                errorMessage: '',
                isReset: action.payload.isReset,
            });
        case CREATEPASS_ERROR:
            return Object.assign({}, state, {
                createPassLoading: false,
                errorMessage: action.payload.message,
                isReset: action.payload.isReset,
            });
        case VERIFYUSER_REQUEST:
            return Object.assign({}, state, {
                verifyLoading: true,
                isVerified: false,
                errorMessage: '',
            });
        case VERIFYUSER_SUCCESS:
            return Object.assign({}, state, {
                verifyLoading: false,
                errorMessage: action.payload.message,
                isVerified: action.payload.isVerified,
            });
        case VERIFYUSER_ERROR:
            return Object.assign({}, state, {
                verifyLoading: false,
                errorMessage: action.payload.message,
                isVerified: action.payload.isVerified,
            });


        default:
            return state;
    }
}

export default auth;


