import React, { useState, useEffect } from "react";
import "./Cart.scss";
import { baseURL } from "../../../config/config";
import NumberFormat from "react-number-format";

//REDUX
import { connect } from "react-redux";
import checkoutActions from "../../../redux/actions/checkout";
import eventActions from "../../../redux/actions/events";

//images
import closeRed from "../../../assets/images/close-red.svg";
import BrokenImage from "../../../assets/images/BrokenImage.webp";

//Components
import EditEventBookingModal from "./EditEventBookingModal";

function CartItem(props) {
  const [thisQuantity, setThisQuantity] = useState(props.quantity);
  useEffect(() => {
    props.dispatch(
      checkoutActions.updateQuantity(props.cart, props.index, thisQuantity)
    );
  }, [thisQuantity]);

  const handleMinus = () => {
    if (props.isEvent === false) {
      setThisQuantity(thisQuantity - 1);
    } else {
      props.dispatch(eventActions.eventById({ eventId: props.zone.event_id }));
      props.dispatch(
        checkoutActions.handlePopup({
          open: true,
          type: "update_reservation",
          selectedEvent: props.zone,
        })
      );
    }
  };

  const hanldePlus = () => {
    if (props.isEvent === false) {
      setThisQuantity(thisQuantity + 1);
    } else {
      props.dispatch(eventActions.eventById({ eventId: props.zone.event_id }));
      props.dispatch(
        checkoutActions.handlePopup({
          open: true,
          type: "update_reservation",
          selectedEvent: props.zone,
        })
      );
    }
  };

  const removeItem = () => {
    let type = "";
    if (props.isEvent === true) {
      type = "events";
    } else {
      type = "shop";
    }
    props.dispatch(
      checkoutActions.removeCartItem(props.cart, type, props.zone)
    );
  };
  const updateQuantity = (e) => {
    props.dispatch(
      checkoutActions.updateQuantity({
        index: props.index,
        cart: props.cart,
        type: e,
      })
    );
  };

  return (
    <div className="borders">
      <div className="items w-100">
        <div className="d-inline-block centerL">
          <div className="small_wilson_img">
            <img
              src={props.image ? `${baseURL}/${props.image}` : BrokenImage}
              alt=""
            />
          </div>
        </div>
        <div className="d-inline-block centerR">
          <div className="d-inline-block">
            <div className="item_name order_now" title={props.name}>
              {props?.name && props?.name?.length > 50
                ? props.name.substring(0, 50) + "..."
                : props.name}
            </div>
            <div className="item_price order_now">
              {props.isEvent === false ? (
                <NumberFormat
                  value={Number(props.price).toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              ) : (
                <p>{`$${Number(props.grand_total / props.quantity).toFixed(
                  2
                )}/CHILD`}</p>
              )}
            </div>
            {props.size === "fixed" ? null : (
              <div className="item_size_title order_now">
                Size: <span className="item_size">{props.size}</span>
              </div>
            )}
            <div className="event_quantity_div">
              <div className="font-14 bold pt-4 white order_now">QTY</div>
              <div className="cart_quantity_add_container order_now row no-gutters ">
                <div className="col-3">
                  {(thisQuantity > 1 || props.quantity >1 )  && (
                    <button
                      className="cart_quantity_button_minus bold"
                      onClick={handleMinus}
                    >
                      -
                    </button>
                  )}
                </div>
                <div className="white font-18 bold col-6 text-center">
                  {props.quantity}
                </div>

                <div className="col-3">
                  {(props?.isEvent === true &&
                    props?.isLoggedIn &&
                    props.getDetails?.childrens?.length > props.quantity) ||
                  (props.isEvent === false &&
                    thisQuantity < props.maxQuantity) ? (
                    <button
                      className="cart_quantity_button_plus bold"
                      onClick={hanldePlus}
                    >
                      +
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          removeItem();
        }}
        className="remove_from_cart pointer"
      >
        <img src={closeRed} alt="close" />
        <span className="red">REMOVE FROM CART</span>
      </div>
      {props?.isEvent && props?.open === true && props?.eventById && (
        <EditEventBookingModal open={props?.open} />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  cart: state.checkout.cart,
  open: state.checkout.open,
  eventById: state.events.eventId,
  isLoggedIn: state.auth.token,
  getDetails: state.account.getDetails,
});
export default connect(mapStateToProps)(CartItem);
