import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';

//Redux
import { connect } from 'react-redux';
import checkoutActions from '../../../redux/actions/checkout';

//CSS
import './EditEventBookingModal.scss';
import closeRed from '../../../assets/images/close-red.svg';

//components
import FormInput from '../FormInput/FormInput';
import { Link } from 'react-router-dom';
import ChildrensEditReservation from '../../pages/events/eventBookingStep1/ChildrensEditReservation';
import RedButton from '../FormInput/RedButton';

export const EditEventBookingModal = (props) => {
    
    const [kids, setKids] = useState([]); 
    const [selectedKids, setSelectedKids] = useState([]);
    const [maximumSelection, setMaximumSelection] = useState(0);
    const [datesError, setDatesError] = useState('');

    const handleClose = () => {
      props.dispatch(checkoutActions.handlePopup({open: false, type: '', selectedEvent: ''}))
    };

    const errorDate = (child) => {
      let count = 0;
      let is_error = false;
      let selected_program_details = props.eventById?.programs?.filter(program => program.id === props.selectedEvent.program_id);
      let maxSelection = selected_program_details[0]?.number_of_days;
      child?.programDates?.map((pd)=>{
        if(pd.checked===true){
          count++;
        }
      })
      if(count<maxSelection){
        is_error = true;
      }
      return is_error;
    }

    const handleSave = () => {
      let updated_event = props.selectedEvent;
      updated_event = {
        ...updated_event,
        grand_total: updated_event.price * selectedKids.length,
        children: selectedKids,
        quantity: selectedKids.length
      }
      let checkError = false;
      updated_event.children?.map((child)=>{
        if(errorDate(child)===true){
          checkError = true;
        }
      })
      if(checkError===true){
        setDatesError('Please select dates for all selected children');
      }
      else{
        setDatesError('');
        props.dispatch(checkoutActions.updateBooking(props.cart, updated_event));
      }
    };

    const populateDates = () => {
        let finalDates = [];
        let selected_program_details = [];
        let maxSelection = 0;
        if(props.eventById?.programs){
            selected_program_details = props.eventById?.programs?.filter(program => program.id === props.selectedEvent.program_id);
            maxSelection = selected_program_details[0].number_of_days;
            setMaximumSelection(maxSelection);
            props.eventById?.recurences?.map((zone, id) => {
                if (maxSelection === props.eventById?.recurences?.length) {
                  finalDates.push({
                    ...zone,
                    checked: true,
                  });
                } else {
                  finalDates.push({
                    ...zone,
                    checked: false,
                  });
                }
              });
        }
        return finalDates;
      };

    const getRecurrenceId = (programDates) => {
        let recurrence_id = [];
        programDates?.map((prog) => {
            if (prog.checked === true) {
              recurrence_id.push(prog.id);
          }
        })
        return recurrence_id;
    }


    useEffect(()=>{
      let all_kids = [];
      let selected_kids = [];
      if(props.selectedEvent && props.userDetails?.childrens){
        selected_kids = props.selectedEvent?.children?.filter(child1 => props.userDetails?.childrens?.some(child2 => child1.id === child2.id));
        props.userDetails?.childrens?.map((child) => {
            all_kids.push({
                conditionNote: child.medical_details,
                dob: child.dob,
                dobChanged: false,
                firstName: child.first_name,
                firstNameChanged: false,
                id: child.id,
                image: "",
                lastName: child.last_name,
                lastNameChanged: false,
                programDates: populateDates(),
                recurrence_id: [],
                schoolChanged: false,
                school_id: child.school_id,
                school_name: child.school_name,
            });
        });
        let modified_kids = [];
        all_kids.map((kid)=>{
          let changed_kid = {
            ...kid,
            recurrence_id: getRecurrenceId(kid.programDates)
          };
          modified_kids.push(changed_kid);
        });
        modified_kids.map((kid) => {
            let kids_selected = selected_kids.filter(child => child.id === kid.id);
            if(kids_selected.length>0){
                if(kid.id===kids_selected[0].id){
                  modified_kids.splice(modified_kids.indexOf(kid),1,kids_selected[0])  
                }
            }
        })
        setKids(modified_kids);
        setSelectedKids(selected_kids);
      }


    }, [props.selectedEvent, props.eventById]);
  
    const body = (
      props.selectedEvent!=={} && <div className="paper">
        <div className="modal_heading">
        <h1>Update Booking For {props.selectedEvent.name}</h1>
        <div>
          <IconButton onClick={handleClose} style={{ border:"none", outline:"none"}}aria-label="close popup" color="primary" disableFocusRipple={true}>
            <img src={closeRed} className="close_icon" alt='close popup'/>
          </IconButton>
        </div>
        </div>
        {props.userDetails ? 
        <div>
        <h4>Select the child/children you'd like to register</h4>
        {selectedKids.length>0 && <p className="error">(If you wish to remove children, remove them from the list and click proceed)</p>}
        <FormInput
            inputType='multipleSelect'
            fullWidth={true}
            placeholder='Children to be added'
            id="select_children"
            options={kids}
            selectedKids={selectedKids}
            setSelectedKids={setSelectedKids}
            setKids={setKids}
            name='children'
        />
        <br />
        <ChildrensEditReservation
          childrenFields={selectedKids}
          setChildrenFields={setSelectedKids}
          maxSelection={maximumSelection}
        />
        <br />
        {datesError!=='' && <p className='error'>{datesError}</p>}
        <br />
        {selectedKids.length===0 && <p className="error">Please Select at least one child to proceed</p>}
        <RedButton 
          disabled={selectedKids.length===0 ? true : false}
          value="Proceed"
          loading={false}
          handleClick={handleSave}
        />
        </div>
        :
        <div>
        <h4>Please {" "}
            <Link to="/login" onClick={()=> props.dispatch(checkoutActions.handlePopup({open: false, type:'', selectedEvent: {}}))}>
            login
            </Link>
            {" "} to proceed
        </h4>
        </div>
        }
      </div>
      
    );


    return (
        <div>
          <Modal
            open={props.open}
            onClose={handleClose}
            aria-labelledby="Edit Reservation"
            aria-describedby="Edit Reservation"
          >
            {body}
          </Modal>
        </div>
      );
    }


    const mapStateToProps = (state) => ({
        cart: state.checkout.cart,
        open: state.checkout.open,
        type: state.checkout.type,
        selectedEvent: state.checkout.selectedEvent,
        userDetails: state.account.getDetails,
        eventById: state.events.eventId,
    });
    
    export default connect(mapStateToProps)(EditEventBookingModal);