import {
    CONTACT_US_REQUEST,
    CONTACT_US_SUCCESS,
    CONTACT_US_ERROR,
} from '../actions/contactus';

const initialState = {
    contactUsLoading:false,
};

function contactus(state = initialState, action) {
    switch (action.type) {
        case CONTACT_US_REQUEST:
            return Object.assign({}, state, {
                contactUsLoading: true,
            });
        case CONTACT_US_SUCCESS:
            return Object.assign({}, state, {
                contactUsLoading: false,
            });
        case CONTACT_US_ERROR:
            return Object.assign({}, state, {
                contactUsLoading: false,
            });
        default:
            return state;
    }
}

export default contactus;