import React, { Suspense, lazy, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";

/* COMPONENTS */
import Loading from "../components/common/loading/Loading";

/* PAGES */
const Layout = lazy(() => import("../components/common/layout"));
const ErrorPage = lazy(() => import("../pages/error"));

const App = (props) => {
  const ENV = process.env.REACT_APP_ENV;

  useEffect(() => {
    const getCookie = (cname) => {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    };

    if (
      !localStorage.getItem("rememberMe") &&
      !(getCookie("loggedIn") === "true")
    ) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("name");
      localStorage.removeItem("email");
      localStorage.removeItem("uuid");
    }
  }, []);

  return (
    <HelmetProvider>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/error">
            <ErrorPage />
          </Route>

          <Route path="/">
            <Layout />
          </Route>

          <Route>
            <Redirect to="/error" />
          </Route>
        </Switch>
      </Suspense>
    </HelmetProvider>
  );
};

// Redux
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(withRouter(App));
