import { combineReducers } from 'redux'
import auth from './auth'
import checkout from './checkout'
import events from './events'
import store from './store'
import news from './news'
import account from './account'
import home from './home'
import contactus from './contactus'

const reducers = combineReducers({
    auth,
    checkout,
    events,
    store,
    news,
    account,
    home,
    contactus,
});

export default reducers;
