import {
	getCarouselItems,
} from '../../config/config';
import { toast } from 'react-toastify';

export const GET_CAROUSEL_ITEM_REQUEST = 'GET_CAROUSEL_ITEM_REQUEST';
export const GET_CAROUSEL_ITEM_SUCCESS = 'GET_CAROUSEL_ITEM_SUCCESS';
export const GET_CAROUSEL_ITEM_ERROR = 'GET_CAROUSEL_ITEM_ERROR';


const actions = {
	getCarouselItems: () => async (dispatch) => {
		try {
			dispatch({
				type: GET_CAROUSEL_ITEM_REQUEST,
			});
			await getCarouselItems().then((response) => {
				if (response.data.code === 200) {
					dispatch({
						type: GET_CAROUSEL_ITEM_SUCCESS,
						payload: {
							data:response.data.data
						},
					});
				} else {
					dispatch({
						type: GET_CAROUSEL_ITEM_ERROR,
					});
                    toast.error(response?.data?.message)
				}
			});
		} catch (error) {
			dispatch({
				type: GET_CAROUSEL_ITEM_ERROR,
			});
            toast.error("Get Carousel Item Failed!")
		}
	},

};

export default actions;
