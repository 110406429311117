import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { configurePersistor, configureStore } from './redux/store/store';
import * as serviceWorker from './serviceWorker';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TagManager from 'react-gtm-module';

import App from "./components/App";
import Cart from "./components/common/header/Cart"


import './assets/css/styles.css'
import './assets/css/colors.scss'
import './assets/css/bootstrap.min.css'

// Redux setup
const store = configureStore();
const persistor = configurePersistor(store);
const wrapper = document.getElementById("root");

// SNIPPETS
const tagManagerArgs = { gtmId: 'GTM-PCDQJKQ' }

if(process.env.REACT_APP_ENV==="production"){
  TagManager.initialize(tagManagerArgs);
}

if (wrapper.hasChildNodes()) {
  ReactDOM.hydrate(
    <Provider store={store}>
    <PersistGate loading={<div />} persistor={persistor}>
      <Router>
        <App />
        <Cart />
        <ToastContainer />
      </Router>
    </PersistGate>
  </Provider>,
  wrapper);
} else {
  ReactDOM.render(
    <Provider store={store}>
    <PersistGate loading={<div />} persistor={persistor}>
      <Router>
        <App />
        <Cart />
        <ToastContainer />
      </Router>
    </PersistGate>
  </Provider>,
  wrapper);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
