import {
    LOAD_NEWS_REQUEST,LOAD_NEWS_SUCCESS,LOAD_NEWS_ERROR,
    LOAD_LATEST_NEWS_REQUEST,LOAD_LATEST_NEWS_SUCCESS,LOAD_LATEST_NEWS_ERROR,
    LOAD_NEWS_BY_ID_REQUEST,LOAD_NEWS_BY_ID_SUCCESS,LOAD_NEWS_BY_ID_ERROR,
} from '../actions/news';

const initialState = {
    newsWithFilterLoading:false,
    newsWithFilter:[],
    latestNews:[],
    latestNewsLoading:false,
    newsById:[],
    newsByIdLoading:false,
};

function news(state = initialState, action) {
    switch (action.type) {
        case LOAD_NEWS_REQUEST:
            return Object.assign({}, state, {
                newsWithFilterLoading: true,
                newsWithFilter: [],
            });
        case LOAD_NEWS_SUCCESS:
            return Object.assign({}, state, {
                newsWithFilterLoading: false,
                newsWithFilter: action.payload.data,
            });
        case LOAD_NEWS_ERROR:
            return Object.assign({}, state, {
                newsWithFilterLoading: false,
                newsWithFilter: [],
            });

        case LOAD_LATEST_NEWS_REQUEST:
            return Object.assign({}, state, {
                latestNewsLoading: true,
                latestNews: [],
            });
        case LOAD_LATEST_NEWS_SUCCESS:
            return Object.assign({}, state, {
                latestNewsLoading: false,
                latestNews: action.payload.data,
            });
        case LOAD_LATEST_NEWS_ERROR:
            return Object.assign({}, state, {
                latestNewsLoading: false,
                latestNews: [],
            });

        case LOAD_NEWS_BY_ID_REQUEST:
            return Object.assign({}, state, {
                newsByIdLoading: true,
                newsById: [],
            });
        case LOAD_NEWS_BY_ID_SUCCESS:
            return Object.assign({}, state, {
                newsByIdLoading: false,
                newsById: action.payload.data,
            });
        case LOAD_NEWS_BY_ID_ERROR:
            return Object.assign({}, state, {
                newsByIdLoading: false,
                newsById: [],
            });

        default:
            return state;
    }
}

export default news;