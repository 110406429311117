import React from 'react';
import {
	Checkbox,
	FormControlLabel,
	FormHelperText,
	InputLabel,
	TextField,
	Input,
	Select as SelectMUI,
	MenuItem,
	InputAdornment,
} from '@material-ui/core';
import Autocomplete, { createFilterOptions }from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import './FormInput.scss';
// import Select from 'react-select';

function FormInput({
	inputType,
	id,
	name,
	label,
	placeholder,
	value,
	onChange,
	error,
	helperText,
	fullWidth,
	extra,
	type,
	multiline,
	rowsMax,
	emptyOption,
	options,
	loading,
	handleInputChange,
	inputIcon,
	formik,
	school,
	childrenFields,
	setChildrenFields,
	disabled,
	selectedKids,
	setSelectedKids,
	setKids
}) {
	const textInputField = () => {
		return (
			<div className='textInputContainer'>
				<InputLabel
					shrink
					htmlFor={`label-${id}`}
					error={error}
					className='textInputLabel'
				>
					{label}
				</InputLabel>
				<TextField
					autoComplete="new-password"
					className='textInput'
					fullWidth={fullWidth ? true : false}
					id={id}
					name={name}
					variant='filled'
					disabled={disabled}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					error={error}
					helperText={helperText}
					aria-describedby={`helper-${id}`}
					type={type || 'text'}
					multiline={multiline || false}
					minRows={rowsMax}
					maxRows={rowsMax}
					InputProps={{
						endAdornment: inputIcon?<InputAdornment position="end">{inputIcon}</InputAdornment>:<></>,
					}}
				/>
				<FormHelperText id={`helper-${id}`}>{extra}</FormHelperText>
			</div>
		);
	};

	const checkboxField = () => {
		return (
			<div className='checkboxContainer'>
				<FormControlLabel
					control={
						<Checkbox
							name={name}
							checked={value}
							onChange={onChange}
							icon={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='18'
									height='18'
									viewBox='0 0 18 18'
								>
									<path
										id='Path'
										d='M16,0H2A2,2,0,0,0,0,2V16a2,2,0,0,0,2,2H16a2,2,0,0,0,2-2V2A2,2,0,0,0,16,0Z'
										fill='#79769f'
									/>
								</svg>
							}
							checkedIcon={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='18'
									height='18'
									viewBox='0 0 18 18'
								>
									<path
										id='_Color'
										data-name=' ↳Color'
										d='M16,18H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H16a2,2,0,0,1,2,2V16A2,2,0,0,1,16,18ZM3.41,7.59h0L2,9l5,5,9-9L14.59,3.58,7,11.17,3.41,7.59Z'
										fill='#d91c24'
									/>
								</svg>
							}
						/>
					}
					label={label}
				/>
			</div>
		);
	};

	// const arrow = (

	// );

	const SelectInput = () => {
		return (
			<div className='selectInputContainer'>
				<InputLabel error={error} className='textInputLabel' shrink id={id}>
					{label}
				</InputLabel>
				<SelectMUI
					id={id}
					labelId={id}
					value={value}
          			variant="filled"
					fullWidth={fullWidth ? true : false}
					onChange={onChange}
					onSelect={onChange}
					error={error}
					helpertext={helperText}
					IconComponent={() => {
						return (
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='16.041'
								height='9.724'
								viewBox='0 0 16.041 9.724'
							>
								<g
									id='Group_7539'
									data-name='Group 7539'
									transform='translate(16.041) rotate(90)'
								>
									<path
										id='Path_3138'
										data-name='Path 3138'
										d='M5.822,8.01,0,14.08l2.044,1.961L9.724,8.01,2.044,0,0,1.961Z'
										fill='#79769F'
										fillRule='evenodd'
									/>
								</g>
							</svg>
						);
					}}
				>
					{emptyOption && (
						<MenuItem value=''>
							<em>{emptyOption}</em>
						</MenuItem>
					)}
					{options?.map((zone, i) => {
						return (
							<MenuItem key={i} value={zone.value}>
								{zone.label}
							</MenuItem>
						);
					})}
				</SelectMUI>
				<FormHelperText id={`helper-${id}`}>{extra}</FormHelperText>
			</div>
		);
	};

	const SelectChildrenAKV = () => {
		return (
			<div className='selectInputContainer'>
				<InputLabel style={{color:'black'}} shrink id={id}>
					{label}
				</InputLabel>
				<SelectMUI
					id={id}
					labelId={id}
					value={value}
          			variant="filled"
					placeholder='Select a child'
					fullWidth={fullWidth ? true : false}
					onChange={onChange}
					helpertext={helperText}
					defaultValue="op1"
				>
					{emptyOption && (
						<MenuItem value="op1">
							{emptyOption}
						</MenuItem>
					)}
					{options?.map((zone, i) => {
						return (
							<MenuItem key={`kid-${i}`} value={zone}>
								{`${zone.first_name} ${zone.last_name} `}
							</MenuItem>
						);
					})}
				</SelectMUI>
			</div>
		);
	};

	const autocompleteSelect=()=>{
		const handleChange = (e) =>{
			const school_name = e.target.value;
			formik.setFieldValue(`children.${id}.school_name`, school_name)
		}
		const filterOptions = createFilterOptions({
			matchFrom: 'any',
			limit: 500,
		  });
		return(
			<div className='textInputContainer'>
			<InputLabel error={error} className='textInputLabel' shrink id={id}>
				{label}
			</InputLabel>
			<Autocomplete
				options={options}
				name={name}
				id={id}
				filterOptions={filterOptions}
				getOptionLabel={(option) => option.label}
				value={value}
				onChange={(event, newValue) => {
					if(newValue!==null){
						if(childrenFields){
							let items = [...childrenFields];
    						let item = {
      						...items[id],
      						school_id: value.value,
      						school_initial_name: value.label,
      						schoolChanged: true,
    					};
    					items[id] = item;
    					setChildrenFields(items);
						}
						formik.setFieldValue(`children.${id}.school_id`, newValue.value)
						formik.setFieldValue(`children.${id}.school_initial_name`, newValue.label)
					}
					else{
						formik.setFieldValue(`children.${id}.school_id`, '')
						formik.setFieldValue(`children.${id}.school_initial_name`, '')
					}
					
				}}
				getOptionSelected={(option, value) => option.value === value.value}
				renderInput={(params) => <TextField error={error} {...params} className='autocompleteSelect_school'placeholder='School Name'/>}
				/>
				{value.label==='Other' &&
				 <TextField 
				 	error={Boolean(formik.values && formik.values.children && formik.values.children[id] && formik.values?.children[id]?.school_name === '' && formik.values && formik.values.children && formik.values.children[id] && formik.values?.children[id]?.school_initial_name === 'Other')} 
					value={school} 
					required
					onChange={(e)=>{
						handleChange(e)
					}} 
					style={{width: '100%', paddingTop: '10px'}} 
					className='autocompleteSelect_school'
					placeholder='School Name'
					helperText={
						formik.values && formik.values.children && formik.values.children[id] && formik.values?.children[id]?.school_name === '' && formik.values && formik.values.children && formik.values.children[id] && formik.values?.children[id]?.school_initial_name === 'Other' ? 'Please specify a school' : ''
					}
					/>
					}
			<FormHelperText error={error}>{helperText}</FormHelperText>
			<FormHelperText id={`helper-${id}`}>{extra}</FormHelperText>
			</div>
		)
	}

	const multipleSelect=()=>{
		let selected_array = [...selectedKids]
		const check_if_exists = (item) => {
			let result = false;
			selectedKids.map((selected_kid)=>{
				if(selected_kid.id=== item.id){
					result = true;
				}
			});
			return result;
		}
		const  getStyles = (value, pe, theme) => {
			// return {
			//   fontWeight:
			// 	personName.indexOf(name) === -1
			// 	  ? theme.typography.fontWeightRegular
			// 	  : theme.typography.fontWeightMedium,
			// };
		  }
		const handleChange = (e) =>{
			console.log('e', e.target.value)
			setSelectedKids(e.target.value)
		}
		return(
			<div className='textInputContainer'>
			<Select
				displayEmpty
				multiple
				value={selected_array}
				className='autocompleteSelect_children'
				fullWidth={fullWidth}
				onChange={(e)=>{
					handleChange(e)
				}}
				input={<Input id="select-multiple-chip" />}
				renderValue={(selected) => (
					
					<div style={{display: 'flex',flexWrap: 'wrap'}}>
					{selected.map((option) => (
						<Chip key={'op-'+option.id} label={`${option.firstName} ${option.lastName}`}style={{margin: 2}} />
					))}
					</div>
				)}
					
				MenuProps={{
					style: {
					  maxHeight: 48 * 4.5 + 8,
					  width: 250,
					},
				  }}
				>
				{options.map((option) => (
					<MenuItem key={'menuop-'+option.id} value={option} style={check_if_exists(option) ? {fontWeight: 'bold'} : {fontWeight: 'regular'}}>
					{option.firstName + ' ' + option.lastName}
					</MenuItem>
				))}
			</Select>
			</div>
		)
	}

	const renderInput = () => {
		switch (inputType) {
			case 'textField':
				return textInputField();
			case 'checkbox':
				return checkboxField();
			case 'select':
				return SelectInput();
			case 'autocompleteSelect':
				return autocompleteSelect();
			case 'multipleSelect':
				return multipleSelect();
			case 'childrenAKV':
				return SelectChildrenAKV(); 
			default:
				return textInputField();
		}
	};

	return renderInput();
}

export default FormInput;
