import { LoadHomeProducts, LoadProductDetails, LoadProductsCollectionWithFilter, LoadProductCategories, loadCategoryDetails } from '../../config/config'

export const LOAD_HOME_PRODUCTS_REQUEST = 'LOAD_HOME_PRODUCTS_REQUEST';
export const LOAD_HOME_PRODUCTS_SUCCESS = 'LOAD_HOME_PRODUCTS_SUCCESS';
export const LOAD_HOME_PRODUCTS_ERROR = 'LOAD_HOME_PRODUCTS_ERROR';

export const LOAD_PRODUCT_DETAILS_REQUEST = 'LOAD_PRODUCT_DETAILS_REQUEST';
export const LOAD_PRODUCT_DETAILS_SUCCESS = 'LOAD_PRODUCT_DETAILS_SUCCESS';
export const LOAD_PRODUCT_DETAILS_ERROR = 'LOAD_PRODUCT_DETAILS_ERROR';

export const LOAD_PRODUCTS_COLLECTION_FILTER_REQUEST = 'LOAD_PRODUCTS_COLLECTION_FILTER_REQUEST';
export const LOAD_PRODUCTS_COLLECTION_FILTER_SUCCESS = 'LOAD_PRODUCTS_COLLECTION_FILTER_SUCCESS';
export const LOAD_PRODUCTS_COLLECTION_FILTER_ERROR = 'LOAD_PRODUCTS_COLLECTION_FILTER_ERROR';

export const LOAD_PRODUCT_CATEGORIES_REQUEST = 'LOAD_PRODUCT_CATEGORIES_REQUEST';
export const LOAD_PRODUCT_CATEGORIES_SUCCESS = 'LOAD_PRODUCT_CATEGORIES_SUCCESS';
export const LOAD_PRODUCT_CATEGORIES_ERROR = 'LOAD_PRODUCT_CATEGORIES_ERROR';

export const LOAD_CATEGORY_DETAILS_REQUEST = 'LOAD_CATEGORY_DETAILS_REQUEST';
export const LOAD_CATEGORY_DETAILS_SUCCESS = 'LOAD_CATEGORY_DETAILS_SUCCESS';
export const LOAD_CATEGORY_DETAILS_ERROR = 'LOAD_CATEGORY_DETAILS_ERROR';

const actions = {
    loadHomeProducts: () => async (dispatch) => {
        try {
            dispatch({
                type: LOAD_HOME_PRODUCTS_REQUEST,
            })

            await LoadHomeProducts().then((response) => {
                // console.log('HOME STORE', response)
                if (response.data.code === 200) {
                    dispatch({
                        type: LOAD_HOME_PRODUCTS_SUCCESS,
                        payload: {
                            data: response.data.data,
                        },
                    });
                } else {
                    dispatch({
                        type: LOAD_HOME_PRODUCTS_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                }
            })
        } catch (error) {
            dispatch({
                type: LOAD_HOME_PRODUCTS_ERROR,
                payload: {
                    message: 'ERROR Loading Home Page Products',
                },
            });
        }
    },

    LoadProductsCollectionWithFilter: (data) => async (dispatch) => {
        try {
            dispatch({
                type: LOAD_PRODUCTS_COLLECTION_FILTER_REQUEST,
            })
            
            await LoadProductsCollectionWithFilter(data).then((response) => {
        
                
                if (response.data.code === 200) {
                    dispatch({
                        type: LOAD_PRODUCTS_COLLECTION_FILTER_SUCCESS,
                        payload: {
                            data: response.data.data,
                        },
                    });
                } else {
                    dispatch({
                        type: LOAD_PRODUCTS_COLLECTION_FILTER_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                }
            })
        } catch (error) {
            dispatch({
                type: LOAD_PRODUCTS_COLLECTION_FILTER_ERROR,
                payload: {
                    message: 'ERROR Loading Product details',
                },
            });
        }
    },


    loadProductdetails: (data) => async (dispatch) => {
        try {
            dispatch({
                type: LOAD_PRODUCT_DETAILS_REQUEST,
            })

            await LoadProductDetails(data).then((response) => {
                // console.log('product', response.data)
                if (response.data.code === 200) {
                    dispatch({
                        type: LOAD_PRODUCT_DETAILS_SUCCESS,
                        payload: {
                            data: response.data.data,
                        },
                    });
                } else {
                    dispatch({
                        type: LOAD_PRODUCT_DETAILS_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                }
            })
        } catch (error) {
            dispatch({
                type: LOAD_PRODUCT_DETAILS_ERROR,
                payload: {
                    message: 'ERROR Loading Product details',
                },
            });
        }
    },


    loadProductCategories: () => async (dispatch) => {
        try {
            dispatch({
                type: LOAD_PRODUCT_CATEGORIES_REQUEST,
            })

            await LoadProductCategories().then((response) => {
                // console.log('categories', response)
                if (response.data) {
                    dispatch({
                        type: LOAD_PRODUCT_CATEGORIES_SUCCESS,
                        payload: {
                            data: response.data,
                        },
                    });
                } else {
                    dispatch({
                        type: LOAD_PRODUCT_CATEGORIES_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                }
            })
        } catch (error) {
            dispatch({
                type: LOAD_PRODUCT_CATEGORIES_ERROR,
                payload: {
                    message: 'ERROR Loading Product Categories',
                },
            });
        }
    },

    loadCategoryDetails: (data) => async (dispatch) => {
        try {
            dispatch({
                type: LOAD_CATEGORY_DETAILS_REQUEST,
            })

            await loadCategoryDetails(data).then((response) => {
                if (response?.data?.data) {
                    dispatch({
                        type: LOAD_CATEGORY_DETAILS_SUCCESS,
                        payload: {
                            data: response?.data?.data,
                        },
                    });
                } else {
                    dispatch({
                        type: LOAD_CATEGORY_DETAILS_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                }
            })
        } catch (error) {
            dispatch({
                type: LOAD_CATEGORY_DETAILS_ERROR,
                payload: {
                    message: 'ERROR Loading Product Categories',
                },
            });
        }
    },


}

export default actions