import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
import accountActions from "../../../../redux/actions/account";
import eventActions from "../../../../redux/actions/events";

//redux
import { connect } from "react-redux";

//CSS
import "./EventBookingChild.scss";
import "./EventBookingParent.scss";
import "./validation.scss";

//images
import CheckBoxUnchecked from "../../../../assets/images/CheckBoxUnchecked.svg";

function ChildrensEditReservation({
  childrenFields,
  setChildrenFields,
  maxSelection,
  maxReached,
  changeMaxReached
}) {
  const [count, setCount] = useState([]);


  const handleSelectDates = (index, i, checked, zone) => (e) => {

    let newSelectionError = [...maxReached];
    let availableDates = zone.programDates;
    let all = availableDates;
    let setAsFirstKidCount = 0;
    all.map((one)=>{
      if(one.checked===true){
        setAsFirstKidCount+=1;
      }
    })
    let find_count = count.find( ct => zone.id == ct.id);
    let newCount = find_count? find_count.count : setAsFirstKidCount? setAsFirstKidCount : 0;
    let updated;
    if (newCount < maxSelection) {
      if (!checked) {
        newCount += 1;
      } else {
        newCount -= 1;
      }
      updated = {
        ...availableDates[index],
        checked: !checked,
      };
    } else if (newCount === maxSelection) {
      if (!checked) {
        updated = {
          ...availableDates[index],
          checked: checked,
        };
      } else {
        newCount -= 1;
        updated = {
          ...availableDates[index],
          checked: !checked,
        };
      }
    }

    if (newCount === maxSelection) {
      newSelectionError[zone.childId] = true;
    } else {
      newSelectionError[zone.childId] = false;
    }
    changeMaxReached(newSelectionError);
    let thisCount = [...count];
    thisCount[count.indexOf(find_count)] = {id: zone.childId,count: newCount};
    setCount(thisCount);

    all[index] = updated;

    let items = [...childrenFields];

    let item = {
      ...items[i],
      programDates: all,
      recurrence_id: getRecurrenceId(all),
    };
    items[i] = item;
    setChildrenFields(items);
  };


  const handleSetAsFirst = (i) => {
    let data = [];
    let firstChildDates = childrenFields[0].programDates;
    let count = 0;
    let max_reached = [...maxReached];
    childrenFields?.map((zone, index) => {
      if (index === i) {
        let thisChildDates = [];
        zone.programDates?.map((zone2, index2) => {
          thisChildDates.push({
            ...zone2,
            checked: firstChildDates[index2].checked,
          });
          if(firstChildDates[index2].checked===true){
            count+=1;
          }
        });
        data.push({ ...zone, programDates: thisChildDates });
      } else {
        data.push(zone);
      }
      if(count === maxSelection){
        max_reached[zone.childId] = true;
      }
    });
    changeMaxReached(max_reached)
    let children_with_recurrence_dates = [];
    data.map((kid)=>{
      let updated_kid = {
        ...kid,
        recurrence_id: getRecurrenceId(kid.programDates)
      }
      children_with_recurrence_dates.push(updated_kid);
    })
    setChildrenFields(children_with_recurrence_dates);
  };

  const getRecurrenceId = (programDates) => {
    let recurrence_id = [];
    programDates?.map((prog) => {
        if (prog.checked === true) {
          recurrence_id.push(prog.id);
      }
    })
    return recurrence_id;
}

  return (
    <div>
      {childrenFields?.map((zone, i) => {
        return (
          <div key={`ch-${i}`} className="mb-5">
            <Row>
              <Col xs={12}>
                <Row>
                <Col xs={12} lg={12} className="account_field">
                        <b>First Name:</b> {
                        zone.firstName
                        }
                    </Col>
                    <Col xs={12} lg={12} className="account_field">
                        <b>Last Name:</b> {
                        zone.lastName
                        }
                    </Col>
                    <Col xs={12} lg={12} className="account_field">
                        <b>Date of Birth:</b> {
                        zone.dob
                        }
                    </Col>
                    <Col xs={12} lg={12} className="account_field">
                        <b>School Name:</b> {
                        zone.school_name
                        }
                    </Col>
                    <Col xs={12} lg={12} className="account_field">
                        <b>Additional Information:</b> {
                        zone.conditionNote
                        }
                    </Col>
                </Row>
              </Col>
            </Row>

            <div className="eventBookingChild_SelectDateTitle text-upper font-24 bold">
              Select your date
              {i !== 0 ? (
                <div
                  className="font-18 same_as_first"
                  onClick={() => {
                    handleSetAsFirst(i);
                  }}
                >
                  Same As First Child
                </div>
              ) : null}
            </div>
            <div className="row week_container">
              {zone.programDates?.map((prog, index) => {
                return (
                  <div
                    key={`check-${i}-${index}`}
                    className="eventBookingChild_Date font-18"
                  >
                    <Checkbox
                      icon={<img width="24" height="24" src={CheckBoxUnchecked} alt=''></img>}
                      checked={prog?.checked}
                      onChange={handleSelectDates(index, i, prog?.checked, zone)}
                    />
                    {` ${prog?.recurring_type === "daily"
                        ? moment(prog?.start_date).format("DD-MM-YYYY")
                        : `WEEK ${index + 1}`
                      }`}
                  </div>
                );
              })}
            </div>
            {maxReached[zone.childId] && (
              <div>You have reached the limit of selections for this event</div>
            )}
          </div>
        );
      })}

    </div>
  );
}

const mapStateToProps = (state) => ({
  maxReached: state.events.maxReached
});

const mapDispatchToProps = (dispatch) => ({
  getSchools: (data) => dispatch(accountActions.getSchools(data)),
  clearSchool: () => dispatch(accountActions.clearSchool()),
  changeMaxReached: (data) => dispatch(eventActions.changeMaxReached(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChildrensEditReservation);
