//import { toast } from 'react-toastify';
import {
  loginUser,
  logoutUser,
  forgotPass,
  registerUser,
  createPass,
  checkAccount,
  verifyAccount,
  getshippingDetails,
} from "../../config/config";
import { toast } from "react-toastify";
import accountActions from "./account";
import checkoutActions from "./checkout";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

export const FORGOTPASS_REQUEST = "FORGOTPASS_REQUEST";
export const FORGOTPASS_SUCCESS = "FORGOTPASS_SUCCESS";
export const FORGOTPASS_ERROR = "FORGOTPASS_ERROR";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";

export const CREATEPASS_REQUEST = "CREATEPASS_REQUEST";
export const CREATEPASS_SUCCESS = "CREATEPASS_SUCCESS";
export const CREATEPASS_ERROR = "CREATEPASS_ERROR";

export const REGISTERLOGIN_REQUEST = "REGISTERLOGIN_REQUEST";
export const REGISTERLOGIN_SUCCESS = "REGISTERLOGIN_SUCCESS";
export const REGISTERLOGIN_ERROR = "REGISTERLOGIN_EROOR";

export const VERIFYUSER_REQUEST = "VERIFYUSER_REQUEST";
export const VERIFYUSER_SUCCESS = "VERIFYUSER_SUCCESS";
export const VERIFYUSER_ERROR = "VERIFYUSER_ERROR";

const actions = {
  registerLogin: (data, history) => async (dispatch) => {
    try {
      dispatch({
        type: REGISTERLOGIN_REQUEST,
      });
      await checkAccount(data).then((response) => {
        if (response.data.code === 200) {
          // console.log(data);
          if (response.data.data["Account-exist"] === true) {
            history.push("/login");
          } else {
            history.push("/registerview");
          }
          dispatch({
            type: REGISTERLOGIN_SUCCESS,
            payload: {
              data: response.data.data["Account-exist"],
              existEmail: data.email,
            },
          });
        } else {
          toast.error(response.message);
          dispatch({
            type: REGISTERLOGIN_ERROR,
          });
        }
      });
    } catch (error) {
      // console.log(error);
      toast.error(error.message);
      dispatch({
        type: REGISTERLOGIN_ERROR,

        message: "Invalid credentials provided or account is not verified",
      });
    }
  },

  login: (data, history) => async (dispatch) => {
    try {
      dispatch({
        type: LOGIN_REQUEST,
      });
      await loginUser(data).then((response) => {
        let userData = [];
        if (response?.data?.code === 200) {
          userData = response?.data?.data?.user;
          dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              data: response?.data?.data?.user,
            },
          });
          localStorage.setItem(
            "access_token",
            response?.data?.data?.user?.access_token
          );
          // sessionStorage.setItem("loggedIn",true);
          document.cookie = "loggedIn=true";
          localStorage.setItem("name", response?.data?.data?.user?.first_name);
          localStorage.setItem("email", response?.data?.data?.user?.email);
          localStorage.setItem("uuid", response?.data?.data?.user?.uuid);
          dispatch(accountActions.getDetails());
          getshippingDetails("Bearer " + localStorage.access_token).then(
            (res) => {
              if (res?.data?.data === null) {
                let data = {
                  first_name: userData?.first_name,
                  last_name: userData?.last_name,
                  email: userData?.email,
                  mobile: userData?.mobile,
                  street_address: userData?.street_address1,
                  city: userData?.street_address2,
                  suburb: userData?.suburb,
                  state: userData?.state,
                  postal_code: userData?.postal_code,
                };
                if (
                  data?.mobile &&
                  data?.street_address &&
                  data?.suburb &&
                  data?.state &&
                  data?.postal_code
                ) {
                  dispatch(
                    accountActions.editshippingDetails(
                      { data: data },
                      false,
                      history
                    )
                  );
                }
              }
            }
          );
          history.push("/");
        } else {
          dispatch({
            type: LOGIN_ERROR,
            payload: {
              message: response?.data?.message,
            },
          });
          toast.error(response?.data?.message);
          localStorage.removeItem("access_token");
          localStorage.removeItem("name");
          localStorage.removeItem("email");
          localStorage.removeItem("uuid");
        }
      });
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR,
        payload: {
          message: "Invalid credentials provided or account is not verified",
        },
      });
      localStorage.removeItem("access_token");
      localStorage.removeItem("name");
      localStorage.removeItem("email");
      localStorage.removeItem("uuid");
    }
  },

  logOut: (history) => async (dispatch) => {
    try {
      dispatch({
        type: LOGOUT_REQUEST,
      });
      await logoutUser(`Bearer ${localStorage.access_token}`).then(
        (response) => {
          if (response.data.code === 200) {
            dispatch({
              type: LOGOUT_SUCCESS,
            });
            dispatch(checkoutActions.removeEventsFromCart());
            localStorage.removeItem("access_token");
            localStorage.removeItem("name");
            localStorage.removeItem("email");
            localStorage.removeItem("uuid");
            history?.push("/");
          } else {
            // console.log('err', response)
            dispatch({
              type: LOGOUT_ERROR,
              payload: {
                message: response.message,
              },
            });
            localStorage.removeItem("access_token");
            localStorage.removeItem("name");
            localStorage.removeItem("email");
            localStorage.removeItem("uuid");
          }
        }
      );
    } catch (error) {
      // console.log(error.response)
      if (error.response.data.message === "Token has expired") {
        dispatch({
          type: LOGOUT_SUCCESS,
        });
        history?.push("/");
        localStorage.removeItem("access_token");
        localStorage.removeItem("name");
        localStorage.removeItem("email");
        localStorage.removeItem("uuid");
      } else {
        dispatch({
          type: LOGOUT_ERROR,
          payload: {
            message: "Something went wrong, please try again.",
          },
        });
        localStorage.removeItem("access_token");
        localStorage.removeItem("name");
        localStorage.removeItem("email");
        localStorage.removeItem("uuid");
      }
    }
  },

  forgotPassword: (data, history) => async (dispatch) => {
    try {
      dispatch({
        type: FORGOTPASS_REQUEST,
      });
      // console.log('FORGOT', data)
      await forgotPass(data, `Bearer ${localStorage.access_token}`).then(
        (response) => {
          // console.log('FORGOT', response)
          if (response?.data?.code === 200) {
            if (response.data.data) {
              toast.success(response.data.data[0]);
            } else {
              toast.success(
                "an email was sent please check your inbox in order to reset your password"
              );
            }
            // console.log(response);
            localStorage.setItem("forgotEmail", data?.email);
            history.push("/check-email");
            dispatch({
              type: FORGOTPASS_SUCCESS,
              payload: {
                message: "",
              },
            });
          } else {
            toast.error("Error: " + response?.data?.message);
            dispatch({
              type: FORGOTPASS_ERROR,
              payload: {
                message: "Invalid credentials.",
              },
            });
          }
        }
      );
    } catch (error) {
      // console.log(error)
      toast.error("error" + error);
      dispatch({
        type: FORGOTPASS_ERROR,
        payload: {
          message: "Invalid credentials.",
        },
      });
    }
  },

  createPassword: (data, history) => async (dispatch) => {
    try {
      dispatch({
        type: CREATEPASS_REQUEST,
      });
      await createPass(data).then((response) => {
        if (response.data.code === 200) {
          dispatch({
            type: CREATEPASS_SUCCESS,
            payload: {
              message: response?.data?.data?.message,
              isReset: true,
            },
          });
          toast.success("Password Changed");
          localStorage.removeItem("access_token");
          localStorage.removeItem("name");
          localStorage.removeItem("email");
          localStorage.removeItem("uuid");
          history.push("/login");
        } else {
          dispatch({
            type: CREATEPASS_ERROR,
            payload: {
              message: "Invalid credentials.",
              isReset: false,
            },
          });
          toast.error(`Error : ${response.data?.message}`);
        }
      });
    } catch (error) {
      dispatch({
        type: CREATEPASS_ERROR,
        payload: {
          message: "Invalid credentials.",
          isReset: false,
        },
      });
      toast.error(`Error : ${error.message}`);
    }
  },
  verifyUser: (data) => async (dispatch) => {
    try {
      dispatch({
        type: VERIFYUSER_REQUEST,
      });
      await verifyAccount(data).then((response) => {
        if (response.data.code === 200) {
          dispatch({
            type: VERIFYUSER_SUCCESS,
            payload: {
              data: response.data.data.user,
              message: 'Account Successfully Verified',
              isVerified: true,
            },
          });
        }
      });
    } catch (error) {
      dispatch({
        type: VERIFYUSER_ERROR,
        payload: {
          message: 'Account Already Verified',
          isVerified: true,
        },
      });
    }
  },
  register: (token, history) => async (dispatch) => {
    try {
      dispatch({
        type: REGISTER_REQUEST,
      });

      await registerUser(token).then((response) => {
        if (response.data?.code === 200) {
          dispatch({
            type: REGISTER_SUCCESS,
            payload: {
              user: response?.data?.data?.user,
            },
          });
          history.push("/register-email");
        } else {
          toast.error(`Error : ${response?.data?.message}`);
          // console.log('ERROR REGISTER', response)
          dispatch({
            type: REGISTER_ERROR,
            payload: {
              message: response.message,
            },
          });
        }
      });
    } catch (error) {
      // console.log('ERROR REGISTER', error.response)
      toast.error(
        error?.response?.data?.errors || error?.response?.data?.message
      );
      dispatch({
        type: REGISTER_ERROR,
        payload: {
          message: "Invalid credentials provided or account is not verified",
        },
      });
    }
  },

  loginNoRedirect: (data) => async (dispatch) => {
    try {
      dispatch({
        type: LOGIN_REQUEST,
      });
      await loginUser(data).then(async (response) => {
        if (response.data.code === 200) {
          await localStorage.setItem(
            "access_token",
            response?.data?.data?.user?.access_token
          );
          // sessionStorage.setItem("loggedIn",true);
          document.cookie = "loggedIn=true";
          await localStorage.setItem(
            "name",
            response?.data?.data?.user?.first_name
          );
          await localStorage.setItem(
            "email",
            response?.data?.data?.user?.email
          );
          await localStorage.setItem("uuid", response?.data?.data?.user?.uuid);
          await dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              data: response?.data?.data?.user,
            },
          });
          // window.location.reload();
          // history.push('/')
        } else {
          dispatch({
            type: LOGIN_ERROR,
            payload: {
              message: response?.data?.message,
            },
          });
          toast.error(response?.data?.message);
          localStorage.removeItem("access_token");
          localStorage.removeItem("name");
          localStorage.removeItem("email");
          localStorage.removeItem("uuid");
        }
      });
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR,
        payload: {
          message: "Invalid credentials provided or account is not verified",
        },
      });
      localStorage.removeItem("access_token");
      localStorage.removeItem("name");
      localStorage.removeItem("email");
      localStorage.removeItem("uuid");
    }
  },
};

export default actions;
